// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;

// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/email-already-used';
export const LOGIN_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/login-already-used';

export const ARTICULO_81 = [
  {
    key: 1,
    label:
      'I. El marco normativo aplicable al sujeto obligado, en el que deberá incluirse leyes, códigos, reglamentos, decretos de creación, manuales administrativos, reglas de operación, criterios, políticas, entre otros.',
  },
  {
    key: 2,
    label:
      'II. Su estructura orgánica completa, en un formato que permita vincular cada parte de la estructura, las atribuciones y responsabilidades que le corresponden a cada servidor público, prestador de servicios profesionales o miembro de los sujetos obligados, de conformidad con las disposiciones aplicables.',
  },
  {
    key: 3,
    label: 'III . Las facultades de cada área.',
  },
  {
    key: 4,
    label: 'IV. Las metas y objetivos de las áreas de conformidad con sus programas operativos.',
  },
  {
    key: 5,
    label:
      'V. Los indicadores relacionados con temas de interés público o trascendencia social que conforme a sus funciones, deban establecer.',
  },
  {
    key: 6,
    label: 'VI. Los indicadores que permitan rendir cuenta de sus objetivos y resultados.',
  },
  {
    key: 7,
    label:
      'VII. El directorio de todos los servidores públicos, a partir del nivel de jefe de departamento o su equivalente, o de menor nivel, cuando se brinde atención al público; manejen o apliquen recursos públicos; realicen actos de autoridad o presten servicios profesionales bajo el régimen de confianza u honorarios y personal de base. El directorio deberá incluir, al menos el nombre, cargo o nombramiento asignado, nivel del puesto en la estructura orgánica, fecha de alta en el cargo, número telefónico, domicilio para recibir correspondencia y dirección de correo electrónico oficiales.',
  },
  {
    key: 8,
    label:
      'VIII. La remuneración bruta y neta de todos los servidores públicos de base o de confianza, de todas las percepciones, incluyendo sueldos, prestaciones, gratificaciones, primas, comisiones, dietas, bonos, estímulos, ingresos y sistemas de compensación, señalando la periodicidad de dicha remuneración.',
  },
  {
    key: 9,
    label: 'IX. Los gastos de representación y viáticos, así como el objeto e informe de comisión correspondiente.',
  },
  {
    key: 10,
    label:
      'X. El número total de las plazas y del personal de base y confianza, especificando el total de las vacantes, por nivel de puesto, para cada unidad administrativa.',
  },
  {
    key: 11,
    label:
      'XI. Las contrataciones de servicios profesionales por honorarios, señalando los nombres de los prestadores de servicios, los servicios contratados, el monto de los honorarios y el periodo de contratación.',
  },
  {
    key: 12,
    label:
      'XII. La información en versión pública de las declaraciones patrimoniales de los Servidores Públicos que así lo determinen, en los sistemas habilitados para ello, de acuerdo a la normatividad aplicable.',
  },
  {
    key: 13,
    label:
      'XIII. El domicilio de la Unidad de Transparencia, además de la dirección electrónica donde podrán recibirse las solicitudes para obtener la información.',
  },
  {
    key: 14,
    label: 'XIV. Las convocatorias a concursos para ocupar cargos públicos y los resultados de los mismos.',
  },
  {
    key: 15,
    label:
      'XV. La información de los programas de subsidios, estímulos y apoyos, en el que se deberá informar respecto de los programas de transferencia, de servicios, de infraestructura social y de subsidio.',
  },
  {
    key: 16,
    label:
      'XVI. Las condiciones generales de trabajo, contratos o convenios que regulen las relaciones laborales del personal de base o de confianza, así como los recursos públicos económicos, en especie o donativos, que sean entregados a los sindicatos y ejerzan como recursos públicos.',
  },
  {
    key: 17,
    label:
      'XVII. La información curricular, desde el nivel de jefe de departamento o equivalente, hasta el titular del sujeto obligado, así como, en su caso, las sanciones administrativas de que haya sido objeto.',
  },
  {
    key: 18,
    label:
      'XVIII. El listado de Servidores Públicos con sanciones administrativas definitivas, especificando la causa de sanción y la disposición.',
  },
  {
    key: 19,
    label: 'XIX. Los servicios que ofrecen señalando los requisitos para acceder a ellos.',
  },
  {
    key: 20,
    label: 'XX. Los trámites, requisitos y formatos que ofrecen.',
  },
  {
    key: 21,
    label:
      'XXI. La información financiera sobre el presupuesto asignado, así como los informes del ejercicio trimestral del gasto, en términos de la normatividad aplicable.',
  },
  {
    key: 22,
    label: 'XXII. La información relativa a la deuda pública, en términos de la normatividad aplicable.',
  },
  {
    key: 23,
    label:
      'XXIII. Los montos destinados a gastos relativos a comunicación social y publicidad oficial desglosada por tipo de medio, proveedores, número de contrato y concepto o campaña.',
  },
  {
    key: 24,
    label:
      'XXIV. Los informes de resultados de las auditorías al ejercicio presupuestal de cada sujeto obligado que se realicen y, en su caso, las aclaraciones que correspondan.',
  },
  {
    key: 25,
    label: 'XXV. El resultado de la dictaminación de los estados financieros.',
  },
  {
    key: 26,
    label:
      'XXVI. Los montos, criterios, convocatorias y listado de personas físicas o morales a quienes, por cualquier motivo, se les asignen o permita usar recursos públicos o, en los términos de las disposiciones aplicables, realicen actos de autoridad. Asimismo, los informes que dichas personas les entreguen sobre el uso y destino de dichos recursos.',
  },
  {
    key: 27,
    label:
      'XXVII. Las concesiones, contratos, convenios, permisos, licencias o autorizaciones otorgados, especificando los titulares de aquéllos, debiendo publicarse su objeto, nombre o razón social del titular, vigencia, tipo, términos, condiciones, monto y modificaciones, así como si el procedimiento involucra el aprovechamiento de bienes, servicios y/o recursos públicos.',
  },
  {
    key: 28,
    label:
      'XXVIII. La información sobre los resultados sobre procedimientos de adjudicación directa, invitación restringida y licitación de cualquier naturaleza, incluyendo la versión pública del expediente respectivo y de los contratos celebrados.',
  },
  {
    key: 29,
    label: 'XXIX. Los informes que por disposición legal generen los sujetos obligados.',
  },
  {
    key: 30,
    label:
      'XXX. Las estadísticas que generen en cumplimiento de sus facultades, competencias o funciones con la mayor desagregación posible.',
  },
  {
    key: 31,
    label: 'XXXI. Informe de avances programáticos o presupuestales, balances generales y su estado financiero.',
  },
  {
    key: 32,
    label: 'XXXII. Padrón de proveedores y contratistas.',
  },
  {
    key: 33,
    label: 'XXXIII. Los convenios de coordinación de concertación con los sectores social y privado.',
  },
  {
    key: 34,
    label: 'XXXIV. El inventario de bienes muebles e inmuebles en posesión y propiedad.',
  },
  {
    key: 35,
    label:
      'XXXV. Las recomendaciones emitidas por los órganos públicos del Estado mexicano u organismos internacionales garantes de los derechos humanos, así como las acciones que han llevado a cabo para su atención.',
  },
  {
    key: 36,
    label: 'XXXVI. Las resoluciones y laudos que se emitan en procesos o procedimientos seguidos en forma de juicio.',
  },
  {
    key: 37,
    label: 'XXXVII. Los mecanismos de participación ciudadana.',
  },
  {
    key: 38,
    label:
      'XXXVIII. Los programas que ofrecen, incluyendo información sobre la población, objetivo y destino, así como los trámites, tiempos de respuesta, requisitos y formatos para acceder a los mismos.',
  },
  {
    key: 39,
    label: 'XXXIX. Las actas y resoluciones del Comité de Transparencia de los sujetos obligados.',
  },
  {
    key: 40,
    label: 'XL. Todas las evaluaciones y encuestas que hagan los sujetos obligados a programas financiados con recursos públicos.',
  },
  {
    key: 41,
    label: 'XLI. Los estudios financiados con recursos públicos.',
  },
  {
    key: 42,
    label: 'XLII. El listado de jubilados y pensionados y el monto que reciben.',
  },
  {
    key: 43,
    label:
      'XLIII. Los ingresos recibidos por cualquier concepto señalando el nombre de los responsables de recibirlos, administrarlos y ejercerlos, así como su destino, indicando el destino de cada uno de ellos.',
  },
  {
    key: 44,
    label: 'XLIV. Donaciones hechas a terceros en dinero o en especie.',
  },
  {
    key: 45,
    label: 'XLV. El catálogo de disposición y guía de archivo documental.',
  },
  {
    key: 46,
    label:
      'XLVI. Las actas de sesiones ordinarias y extraordinarias, así como las opiniones y recomendaciones que emitan, en su caso, los consejos consultivos.',
  },
  {
    key: 47,
    label:
      'XLVII. Para efectos estadísticos, el listado de solicitudes a las empresas concesionarias de telecomunicaciones y proveedores de servicios o aplicaciones de Internet para la intervención de comunicaciones privadas, el acceso al registro de comunicaciones y la localización geográfica en tiempo real de equipos de comunicación, que contenga exclusivamente el objeto, el alcance temporal y los fundamentos legales del requerimiento, así como, en su caso, la mención de que cuenta con la autorización judicial correspondiente.',
  },
  {
    key: 48,
    label:
      'XLVIII. Cualquier otra información que sea de utilidad o se considere relevante, además de la que, con base en la información estadística, responda a las preguntas hechas con más frecuencia por el público.',
  },
  {
    key: 49,
    label: 'XLIX. Rubros aplicables a la página de Internet.',
  },
];

export const ARTICULO_82 = [
  {
    key: 1,
    label: 'I. El Plan Estatal de Desarrollo, y los planes municipales de desarrollo, según corresponda.',
  },
  {
    key: 2,
    label: 'II. El presupuesto de egresos y las fórmulas de distribución de los recursos otorgados.',
  },
  {
    key: 3,
    label:
      'III. El listado de expropiaciones decretadas y ejecutadas que incluya, cuando menos, la fecha de expropiación, el domicilio, la causa de utilidad pública y las ocupaciones superficiales.',
  },
  {
    key: 4,
    label:
      'IV. El nombre, denominación o razón social y clave del registro federal de los contribuyentes a los que se les hubiera cancelado o condonado algún crédito fiscal, así como los montos respectivos. Asimismo, la información estadística sobre las exenciones previstas en las disposiciones fiscales.',
  },
  {
    key: 5,
    label:
      'V. Los nombres de las personas a quienes se les otorgó patente para ejercer como notarios públicos, así como sus datos de contacto, la información relacionada con el proceso de otorgamiento de la patente y las sanciones que se les hubieran aplicado.',
  },
  {
    key: 6,
    label:
      'VI. La información detallada que contengan los planes de desarrollo urbano, ordenamiento territorial y ecológico, los tipos y usos de suelo, licencias de uso y construcción otorgadas por los gobiernos municipales.',
  },
  {
    key: 7,
    label:
      'VII. Las disposiciones administrativas, directamente o a través de la autoridad competente, con el plazo de anticipación que prevean las disposiciones aplicables al sujeto obligado de que se trate, salvo que su difusión pueda comprometer los efectos que se pretenden lograr con la disposición o se trate de situaciones de emergencia, de conformidad con dichas disposiciones.',
  },
  {
    key: 8,
    label:
      'VIII. Los anteproyectos de iniciativas de Ley y disposiciones administrativas de carácter general por lo menos con veinte días de anticipación a la fecha en que se pretenda someter a la firma del Titular del Poder Ejecutivo, salvo las excepciones señaladas en las leyes.',
  },
];

export const ARTICULO_85 = [
  {
    key: 1,
    label: 'I. El contenido en las gacetas municipales, los resolutivos y acuerdos aprobados por los ayuntamientos.',
  },
  {
    key: 2,
    label:
      'II. Las actas de sesiones de cabildo, los controles de asistencia de los integrantes del Ayuntamiento a las sesiones de cabildo y el sentido de votación de los miembros del cabildo sobre las iniciativas o acuerdos.',
  },
];

export const EJERCICIOS = [
  { value: null, text: 'Seleccione Ejercicio' },
  { value: 2024, text: '2024' },
  { value: 2023, text: '2023' },
  { value: 2022, text: '2022' },
  { value: 2021, text: '2021' },
  { value: 2020, text: '2020' },
  { value: 2019, text: '2019' },
  { value: 2018, text: '2028' },
  { value: 2017, text: '2017' },
];

export const ARTICULOS = [
  { value: null, text: 'Seleccione artículo' },
  { value: 81, text: 'Artículo 81' },
  { value: 82, text: 'Artículo 82' },
  { value: 85, text: 'Artículo 85' },
];

export const FRACCIONES = [
  { value: null, text: 'Seleccione Fracción' },
  { value: 1, text: 'Fracción I (1)' },
  { value: 2, text: 'Fracción II (2)' },
  { value: 3, text: 'Fracción III (3)' },
  { value: 4, text: 'Fracción IV (4)' },
  { value: 5, text: 'Fracción V (5)' },
  { value: 6, text: 'Fracción VI (6)' },
  { value: 7, text: 'Fracción VII (7)' },
  { value: 8, text: 'Fracción VIII (8)' },
  { value: 9, text: 'Fracción IX (9)' },
  { value: 10, text: 'Fracción X (10)' },
  { value: 11, text: 'Fracción XI (11)' },
  { value: 12, text: 'Fracción XII (12)' },
  { value: 13, text: 'Fracción XIII (13)' },
  { value: 14, text: 'Fracción XIV (14)' },
  { value: 15, text: 'Fracción XV (15)' },
  { value: 16, text: 'Fracción XVI (16)' },
  { value: 17, text: 'Fracción XVII (17)' },
  { value: 18, text: 'Fracción XVIII (18)' },
  { value: 19, text: 'Fracción XIX (19)' },
  { value: 20, text: 'Fracción XX (20)' },
  { value: 21, text: 'Fracción XXI (21)' },
  { value: 22, text: 'Fracción XXII (22)' },
  { value: 23, text: 'Fracción XXIII (23)' },
  { value: 24, text: 'Fracción XXIV (24)' },
  { value: 25, text: 'Fracción XXV (25)' },
  { value: 26, text: 'Fracción XXVI (26)' },
  { value: 27, text: 'Fracción XXVII (27)' },
  { value: 28, text: 'Fracción XXVIII (28)' },
  { value: 29, text: 'Fracción XXIX (29)' },
  { value: 30, text: 'Fracción XXX (30)' },
  { value: 31, text: 'Fracción XXXI (31)' },
  { value: 32, text: 'Fracción XXXII (32)' },
  { value: 33, text: 'Fracción XXXIII (33)' },
  { value: 34, text: 'Fracción XXXIV (34)' },
  { value: 35, text: 'Fracción XXXV (35)' },
  { value: 36, text: 'Fracción XXXVI (36)' },
  { value: 37, text: 'Fracción XXXVII (37)' },
  { value: 38, text: 'Fracción XXXVIII (38)' },
  { value: 39, text: 'Fracción XXXIX (39)' },
  { value: 40, text: 'Fracción XL (40)' },
  { value: 41, text: 'Fracción XLI (41)' },
  { value: 42, text: 'Fracción XLII (42)' },
  { value: 43, text: 'Fracción XLIII (43)' },
  { value: 44, text: 'Fracción XLIV (44)' },
  { value: 45, text: 'Fracción XLV (45)' },
  { value: 46, text: 'Fracción XLVI (46)' },
  { value: 47, text: 'Fracción XLVII (47)' },
  { value: 48, text: 'Fracción XLVIII (48)' },
  { value: 49, text: 'Fracción XLIX (49)' },
];

export const TRIMESTRES = [
  { value: null, text: 'Seleccione el Trimestre' },
  { value: 1, text: 'Trimestre 1' },
  { value: 2, text: 'Trimestre 2' },
  { value: 3, text: 'Trimestre 3' },
  { value: 4, text: 'Trimestre 4' },
];

export const MODULOS = [
  { value: null, text: 'Seleccione el Módulo' },
  { value: 'PRIMER', text: 'Trimestre 1' },
  { value: 'SEGUNDO', text: 'Trimestre 2' },
  { value: 'TERCER', text: 'Trimestre 3' },
  { value: 'CUARTO', text: 'Trimestre 4' },
  { value: 'ANUAL', text: 'ANUAL' },
  { value: 'FAISM', text: 'FAISM' },
  { value: 'SEMESTRAL', text: 'SEMESTRAL' },
  { value: 'CPUBLICA', text: 'CUENTA PÚBLICA' },
  { value: 'BGENERAL', text: 'BALANZA GENERAL' },
];

export const APARTADOS = [
  { value: null, text: 'Seleccione el Apartado' },
  { value: 'leyGeneral', text: 'LEY GENERAL' },
  { value: 'iPublica', text: 'INFORMACIÓN PÚBLICA' },
  { value: 'instancia', text: 'INSTANCIA TÉCNICA' },
  { value: 'sevac', text: 'SEVAC' },
  { value: 'leyDisciplina', text: 'LEY DISCIPLINA FINANCIERA' },
  { value: 'gaceta', text: 'GACETA MUNICIPAL' },
];

export const TIPOSFAISM = [
  { value: 1, text: 'FINIQUITO' },
  { value: 2, text: 'CONTRATOS' },
  { value: 3, text: 'ACTAS' },
  { value: 4, text: 'SRFT' },
];

export const TIPOACTA = [
  { value: 1, text: 'ENTREGA' },
  { value: 2, text: 'CIRCUNSTANCIADA' },
];

export const SEMESTRES = [
  { value: 16, text: 'SEMESTRE 1' },
  { value: 26, text: 'SEMESTRE 2' },
];
