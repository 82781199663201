

































import { Component, Inject, VModel, Vue, Watch } from 'vue-property-decorator';
import Contratos from '@/modulos/faism/contratos.vue';
import Actas from '@/modulos/faism/actas/actas.vue';
import Finiquito from '@/modulos/faism/finiquito.vue';
import SRFT from '@/modulos/faism/srft.vue';
import { mapGetters } from 'vuex';
import DocumentosLeyGeneralService from '@/shared/service/documentos-api.service';

interface Documento {
  id: number;
  clave: string;
  nombre: string;
  extension: string;
  url: string;
  acta?: boolean;
  tipoActa?: string;
  contrato?: boolean;
  finiquito?: boolean;
  srft?: boolean;
}

@Component({
  components: {
    contratos: Contratos,
    actas: Actas,
    finiquito: Finiquito,
    srft: SRFT,
  },
  computed: {
    ...mapGetters(['modulo', 'apartado', 'ejercicio', 'trimestre']),
  },
})
export default class FAISM extends Vue {
  @Inject('documentosLeyGeneralService') documentosLeyGeneralService: () => DocumentosLeyGeneralService;

  @VModel({ required: true })
  documentos!: Documento[];

  documentosTemp: any[] = [];

  readonly modulo!: string;

  readonly apartado!: string;

  readonly ejercicio!: number;

  readonly trimestre!: number;

  tipoDocumento = 'CONTRATO';

  @Watch('modulo', { immediate: true })
  consultar() {
    if (this.modulo === 'FAISM') {
      this.consultarDocumentos('contrato', this.tipoDocumento);
    }
  }

  filterDocumentos(type: keyof Documento) {
    console.log(type);
    this.documentosTemp = this.documentos.filter(d => d[type] === true);
    this.setIdsBasedOnType(type);
    this.sortDocumentos();
  }

  setIdsBasedOnType(type: keyof Documento) {
    let contador = 1;
    this.documentosTemp.forEach((doc, index) => {
      if (doc[type]) {
        doc.id = contador++;
      }
    });
  }

  sortDocumentos() {
    this.documentosTemp.sort((a, b) => a.id - b.id);
  }

  consultarDocumentos(tipoFaism: keyof Documento, tipoDocumento: string) {
    this.tipoDocumento = tipoDocumento;
    this.documentos = [];
    this.documentosTemp = [];
    let contador = 1;
    this.documentosLeyGeneralService()
      .obtenerDocumentos(this.apartado, this.modulo, this.ejercicio, this.trimestre, this.tipoDocumento)
      .then(res => {
        this.documentosTemp = res.data;
        procesarDocumentos();
        this.filterDocumentos(tipoFaism);
      });

    const procesarDocumentos = () => {
      this.documentosTemp.forEach(r => {
        const doc = {
          id: contador++,
          clave: r.id,
          nombre: r.nombre,
          extension: this.getExtension(r.mediaType),
          articulo: r.articulo,
          fraccion: r.fraccion,
          url: r.uri,
          contrato: r.contrato,
          acta: r.acta,
          instancia: r.instancia,
          modulo: r.modulo,
          trimestre: r.trimestre,
          finiquito: r.finiquito,
          srft: r.srft,
          cpublica: r.cuentaPublica,
          leyGeneral: r.leyGeneral,
          informacionPublica: r.informacionPublica,
          tipoActa: r.tipoActa,
          contenido: r.contenido,
        };

        this.documentos.push(doc);
      });
    };
  }

  public getExtension(extension: string): string {
    switch (extension) {
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/pdf':
        return 'pdf';
      case 'pdf':
        return 'pdf';
      case 'xls':
        return 'xls';
    }
  }
}
