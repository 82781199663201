








import { Component, VModel, Vue } from 'vue-property-decorator';
import TablaGeneral from '@/tabla-general/tabla-general.vue';

@Component({
  components: {
    'tabla-general': TablaGeneral,
  },
})
export default class ANUAL extends Vue {
  @VModel({ required: true })
  documentos!: any[];
}
