








import { Component, Vue } from 'vue-property-decorator';
import Modulos from '@/modulos/modulos.vue';

@Component({
  components: {
    modulos: Modulos,
  },
})
export default class Ejercicio2018 extends Vue {
  ejercicio2018 = 2018;
}
