























import { Component, VModel, Vue } from 'vue-property-decorator';
import PrimerSemestre from '@/modulos/primer-semestre/primer-semestre.vue';
import SegundoSemestre from '@/modulos/segundo-semestre/segundo-semestre.vue';

@Component({
  components: {
    'primer-semestre': PrimerSemestre,
    'segundo-semestre': SegundoSemestre,
  },
})
export default class SEMESTRAL extends Vue {
  @VModel({ required: true })
  documentos!: any[];

  documentosTemp: any[] = [];

  setTiempo(tiempo: number, tab: number) {
    this.documentosTemp = [];
    this.documentos = [];
    // Dentro del componente <semestral>
    this.$emit('consulta-semestral', { valor1: tiempo, valor2: tab });
    this.documentosTemp = this.documentos.filter(d => d.modulo === 'SEMESTRAL' && d.trimestre === tiempo);
    let contador = 1;
    this.documentosTemp.forEach(r => {
      const doc = {
        id: contador++,
        nombre: r.nombre,
        extension: r.mediaType,
        articulo: r.articulo,
        fraccion: r.fraccion,
        url: r.uri,
        contrato: r.contrato,
        modulo: r.modulo,
      };
      this.documentos.push(doc);
      this.documentos.sort((a, b) => a.nombre - b.nombre);
    });
  }
}
