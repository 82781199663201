























import { Component, Vue } from 'vue-property-decorator';
import { ARTICULO_85 } from '@/constants';

@Component({
  computed: {},
})
export default class Articulo85 extends Vue {
  columnas: any = [
    {
      key: 'id',
      label: 'Fracción',
    },
  ];

  arti85: any[] = [];

  created() {
    ARTICULO_85.forEach(a => {
      const art = {
        id: a.key,
        descripcion: a.label,
      };
      this.arti85.push(art);
    });
  }

  private setEjercicio(ejercicio: string, fraccion: any) {
    this.$store.commit('setArticulo', 85);
    this.$store.commit('setFraccion', fraccion);
    this.$store.commit('setModulo', 'PRIMER');
    this.$store.commit('setTrimestre', 1);
    this.$router.push({ name: 'EJERCICIO_' + ejercicio, params: { general: 'iPublica' } }).catch(() => {});
  }
}
