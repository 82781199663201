var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c("div", [
        _vm.esLeyGeneral
          ? _c("h3", [_vm._v("Ley General de Contabilidad Gubernamental")])
          : _vm._e(),
        _vm._v(" "),
        _vm.esLeyDisciplina
          ? _c("h3", [
              _vm._v(
                "LEY DE DISCIPLINA FINANCIERA DE LAS ENTIDADES FEDERATIVAS Y LOS MUNICIPIOS"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.esInstanciaTecnicaEvaluacion
          ? _c("h3", [_vm._v("INSTANCIA TECNICA DE EVALUACION DEL DESEMPEÑO")])
          : _vm._e(),
        _vm._v(" "),
        _vm.esSevac ? _c("h3", [_vm._v("S E V A C")]) : _vm._e()
      ]),
      _vm._v(" "),
      _vm.esInformacionPublica
        ? _c("div", [
            _c("h5", [
              _vm._v(
                "Artículo: " +
                  _vm._s(_vm.articulo) +
                  " Fracción: " +
                  _vm._s(_vm.fraccion)
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-card", { attrs: { "no-body": "" } }, [_c("ejercicios")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }