




























































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import { VERSION } from '@/constants';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';

@Component
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  @Inject('accountService') private accountService: () => AccountService;
  public version = VERSION ? 'v' + VERSION : '';
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValue = false;

  // colores: string = this.$t('ayuntamientos.tlapa.nombre') as string;
  colores = '#3c6501a2';
  totalImagenes: string = this.$t('ayuntamientos.tlapa.totalImagenes') as string;
  //seguir revisando
  rutaLogo = '../../../content/images/zitlala/logo-zitlala.png';

  created() {
    this.translationService().refreshTranslation(this.currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): void {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    this.$router.push('/', () => {});
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAnyAuthorityValue = value;
      });
    return this.hasAnyAuthorityValue;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }
  // Métodos "es" reordenados y refactorizados
  esInformacionPublica(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsInformacionPublica', apartado);
    this.$store.commit('setApartado', 'iPublica');
  }

  esInstancia(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsInstanciaTecnicaEvaluacion', apartado);
    this.$store.commit('setApartado', 'iTecnica');
  }

  esLeyGeneral(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsLeyGeneral', apartado);
    this.$store.commit('setApartado', 'leyGeneral');
  }

  esLeyDisciplina(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsLeyDisciplina', apartado);
    this.$store.commit('setApartado', 'leyDisciplina');
  }

  esSevac(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsSevac', apartado);
    this.$store.commit('setApartado', 'sevac');
  }

  esGaceta(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsGaceta', apartado);
    this.$store.commit('setApartado', 'gaceta');
  }

  // Métodos de ayuda
  private resetAllStates() {
    this.$store.commit('setEsLeyGeneral', false);
    this.$store.commit('setEsInformacionPublica', false);
    this.$store.commit('setEsInstanciaTecnicaEvaluacion', false);
    this.$store.commit('setEsSevac', false);
    this.$store.commit('setEsLeyDisciplina', false);
    this.$store.commit('setArticulo', 0);
    this.$store.commit('setFraccion', 0);
    this.$store.commit('setGaceta', 0);
    this.$store.commit('setEjercicio', 2024);
  }
}
