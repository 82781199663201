var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c("div", [
      _c(
        "div",
        [
          _c(
            "tabla-general",
            {
              model: {
                value: _vm.documentos,
                callback: function($$v) {
                  _vm.documentos = $$v
                },
                expression: "documentos"
              }
            },
            [_vm._v("Cuarto Trimestre")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }