














import { Component, Inject, Vue } from 'vue-property-decorator';
import FAISM from '@/modulos/faism/faism.vue';
import PrimerTrimestre from '@/modulos/primer-trimestre/primer-trimestre.vue';
import SegundoTrimestre from '@/modulos/segundo-trimestre/segundo-trimestre.vue';
import TercerTrimestre from '@/modulos/tercer-trimestre/tercer-trimestre.vue';
import CuartoTrimestre from '@/modulos/cuarto-trimestre/cuarto-trimestre.vue';
import ANUAL from '@/modulos/anual/anual.vue';
import Semestral from '@/modulos/semestral/semestral.vue';
import CuentaPublica from '@/modulos/cuenta-publica/cuenta-publica.vue';
import SEVAC from '@/modulos/sevac/sevac.vue';
import { mapGetters } from 'vuex';
import DocumentosLeyGeneralService from '@/shared/service/documentos-api.service';
import DocumentosInformacionPublicaService from '@/shared/service/documentos-informacion-publica-api.service';
import DocumentosSevacService from '@/shared/service/documentos-sevac-api.service';
import AccountService from '@/account/account.service';
import axios from 'axios';

@Component({
  components: {
    'primer-trimestre': PrimerTrimestre,
    faism: FAISM,
    'segundo-trimestre': SegundoTrimestre,
    'tercer-trimestre': TercerTrimestre,
    'cuarto-trimestre': CuartoTrimestre,
    anual: ANUAL,
    semestral: Semestral,
    'cuenta-publica': CuentaPublica,
    sevac: SEVAC,
  },
  computed: {
    ...mapGetters([
      'articulo',
      'fraccion',
      'esInformacionPublica',
      'esInstanciaTecnicaEvaluacion',
      'esLeyGeneral',
      'trimestre',
      'modulo',
      'apartado',
      'esSevac',
      'ejercicio',
      'esLeyDisciplina',
      'esGaceta',
    ]),
  },
})
export default class Modulos extends Vue {
  @Inject('documentosLeyGeneralService') documentosLeyGeneralService: () => DocumentosLeyGeneralService;
  @Inject('documentosInformacionPublicaService') documentosInformacionPublicaService: () => DocumentosInformacionPublicaService;
  @Inject('documentosSevacService') documentosSevacService: () => DocumentosSevacService;
  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;

  ejercicio: number;

  documentos: any[] = [];

  documentosTemp: any[] = [];

  readonly esInformacionPublica!: boolean;

  readonly esInstanciaTecnicaEvaluacion!: boolean;

  readonly esLeyGeneral!: boolean;

  readonly trimestre!: number;

  readonly articulo!: number;

  readonly fraccion!: number;

  readonly modulo!: string;

  readonly apartado!: string;

  private apartadoActual = '';

  readonly esSevac!: boolean;

  readonly esLeyDisciplina!: boolean;

  readonly esGaceta!: boolean;

  activeTab = 0;

  trimestreLocal = 0;

  tabs = [
    { name: 'PRIMER', title: '1er Trimestre', component: 'primer-trimestre', trimestre: 1 },
    { name: 'SEGUNDO', title: '2do Trimestre', component: 'segundo-trimestre', trimestre: 2 },
    { name: 'TERCER', title: '3er Trimestre', component: 'tercer-trimestre', trimestre: 3 },
    { name: 'CUARTO', title: '4to Trimestre', component: 'cuarto-trimestre', trimestre: 4 },
    { name: 'ANUAL', title: 'ANUAL', component: 'anual', trimestre: 0 },
    { name: 'FAISM', title: 'FAISM', component: 'faism', trimestre: 0 },
    { name: 'SEMESTRAL', title: 'Semestral', component: 'semestral', trimestre: 16 },
    { name: 'CPUBLICA', title: 'C.Pública', component: 'cuenta-publica', trimestre: 0 },
  ];

  public setModulo(modulo: string, trimestre: number, tab: number) {
    if (modulo === 'FAISM') {
      this.$store.commit('setModulo', modulo);
      this.$store.commit('setTrimestre', trimestre);
      this.documentos = [];
      this.documentosTemp = [];
      return;
    }
    this.$store.commit('setModulo', modulo);
    this.$store.commit('setTrimestre', trimestre);

    this.documentos = [];
    this.documentosTemp = [];

    if (this.esInformacionPublica) {
      this.apartadoActual = 'iPublica';
    } else if (this.esInstanciaTecnicaEvaluacion) {
      this.apartadoActual = 'iTecnica';
    } else if (this.esLeyGeneral) {
      this.apartadoActual = 'leyGeneral';
    } else if (this.esSevac) {
      this.apartadoActual = 'sevac';
    } else if (this.esLeyDisciplina) {
      this.apartadoActual = 'leyDisciplina';
    }

    this.consultarDocumentos(modulo, trimestre);
    this.$store.commit('setModulo', modulo);

    this.$router
      .push({
        name: modulo,
        params: {
          ejercicio: this.ejercicio.toString(10),
          apartado: this.apartadoActual,
          trimestre: trimestre.toString(10),
          pesta: tab.toString(10),
        },
      })
      .catch(() => {});

    this.documentos = [];
  }

  created() {
    window.addEventListener('popstate', this.onBackButtonEvent);
    this.doLogin();
  }

  beforeDestroy() {
    window.removeEventListener('popstate', this.onBackButtonEvent);
  }

  onBackButtonEvent(event: PopStateEvent) {
    if (this.esInformacionPublica) {
      this.$router.push('/informacion-publica');
    } else if (this.esLeyDisciplina) {
      this.$router.push('/ley-disciplina');
    } else if (this.esLeyGeneral) {
      this.$router.push('/ley-general');
    } else if (this.esInstanciaTecnicaEvaluacion) {
      this.$router.push('/instancia-tecnica-evaluacion');
    } else if (this.esSevac) {
      this.$router.push('/sevac');
    } else if (this.esGaceta) {
      this.$router.push('/gaceta-municipal');
    }
  }
  public doLogin(): void {
    const data = { username: 'user', password: 'user', rememberMe: true };
    axios
      .post('api/authenticate', data)
      .then(result => {
        const bearerToken = result.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (this.rememberMe) {
            localStorage.setItem('jhi-authenticationToken', jwt);
            sessionStorage.removeItem('jhi-authenticationToken');
          } else {
            sessionStorage.setItem('jhi-authenticationToken', jwt);
            localStorage.removeItem('jhi-authenticationToken');
          }
        }
        this.authenticationError = false;
        this.$root.$emit('bv::hide::modal', 'login-page');
        this.accountService().retrieveAccount();
        this.consultaApartados();
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }

  consultaApartados() {
    this.trimestreLocal = Number(this.$route.params.trimestre);
    this.activeTab = Number(this.$route.params.pesta);
    if (this.$route.params.apartado === 'leyGeneral') {
      this.isLeyGeneral(true);
      this.consultarDocumentos(this.modulo, this.trimestreLocal);
    } else if (this.$route.params.apartado === 'sevac') {
      this.isSevac(true);
      this.consultarDocumentos(this.modulo, this.trimestreLocal);
    } else if (this.$route.params.apartado === 'leyDisciplina') {
      this.isLeyDisciplina(true);
      this.consultarDocumentos(this.modulo, this.trimestreLocal);
    } else if (this.$route.params.apartado === 'iPublica') {
      this.isIePublica(true);
      this.consultarDocumentos(this.modulo, this.trimestreLocal);
    } else if (this.$route.params.apartado === 'gaceta') {
      this.isGaceta(true);
      this.consultarDocumentos(this.modulo, this.trimestreLocal);
    } else {
      this.consultarDocumentos(this.modulo, this.trimestre);
    }
    this.documentos = [];
    this.documentosTemp = [];
  }

  private consultarDocumentos(modulo: string, trimestre: number) {
    this.documentos = [];
    this.documentosTemp = [];
    let contador = 1;
    if (this.esLeyGeneral || this.esInstanciaTecnicaEvaluacion || this.esLeyDisciplina || this.esGaceta) {
      this.documentosLeyGeneralService()
        .obtenerDocumentos(this.apartado, modulo, this.ejercicio, trimestre, 'GENERAL')
        .then(res => {
          this.documentosTemp = res.data;
          procesarDocumentos();
          if (this.esLeyGeneral) {
            this.apartadoActual = 'leyGeneral';
          } else if (this.esInstanciaTecnicaEvaluacion) {
            this.apartadoActual = 'instancia-tecnica';
          } else if (this.esLeyDisciplina) {
            this.apartadoActual = 'leyDisciplina';
          } else if (this.esGaceta) {
            this.apartadoActual = 'gaceta';
          }
        });
    } else if (this.esInformacionPublica) {
      this.documentosInformacionPublicaService()
        .obtenerDocumentosInformacionPublica(this.apartado, this.ejercicio, trimestre, this.articulo, this.fraccion)
        .then(res => {
          this.documentosTemp = res.data;
          procesarDocumentos();
          this.apartadoActual = 'informacion-publica';
        });
    } else if (this.esSevac) {
      this.documentosSevacService()
        .obtenerDocumentosSevac(this.apartado, this.ejercicio, trimestre)
        .then(res => {
          this.documentosTemp = res.data;
          procesarDocumentos();
          this.apartadoActual = 'sevac';
        });
    }

    const procesarDocumentos = () => {
      this.documentosTemp.forEach(r => {
        const doc = {
          id: contador++,
          clave: r.id,
          nombre: r.nombre,
          extension: this.getExtension(r.mediaType),
          articulo: r.articulo,
          fraccion: r.fraccion,
          url: r.uri,
          contrato: r.contrato,
          acta: r.acta,
          instancia: r.instancia,
          modulo: r.modulo,
          trimestre: r.trimestre,
          finiquito: r.finiquito,
          cpublica: r.cuentaPublica,
          leyGeneral: r.leyGeneral,
          informacionPublica: r.informacionPublica,
          tipoActa: r.tipoActa,
          contenido: r.contenido,
        };

        this.documentos.push(doc);
      });

      this.documentos.sort((a, b) => a - b);
    };
  }

  private resetAllStates() {
    this.$store.commit('setEsLeyGeneral', false);
    this.$store.commit('setEsInformacionPublica', false);
    this.$store.commit('setEsInstanciaTecnicaEvaluacion', false);
    this.$store.commit('setEsSevac', false);
    this.$store.commit('setEsLeyDisciplina', false);
    this.$store.commit('setArticulo', 0);
    this.$store.commit('setFraccion', 0);
  }

  public getExtension(extension: string): string {
    switch (extension) {
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/pdf':
        return 'pdf';
      case 'pdf':
        return 'pdf';
      case 'xls':
        return 'xls';
    }
  }

  isLeyGeneral(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsLeyGeneral', apartado);
    this.$store.commit('setApartado', 'leyGeneral');
    this.$store.commit('setModulo', this.$route.name);
    this.$store.commit('setTrimestre', Number(this.$route.params.trimestre));
  }
  isLeyDisciplina(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setEsLeyDisciplina', apartado);
    this.$store.commit('setApartado', 'leyDisciplina');
    this.$store.commit('setModulo', this.$route.name);
    this.$store.commit('setTrimestre', Number(this.$route.params.trimestre));
  }

  isSevac(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setApartado', 'sevac');
    this.$store.commit('setModulo', this.$route.name);
    this.$store.commit('setTrimestre', Number(this.$route.params.trimestre));
  }

  isIePublica(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setApartado', 'iPublica');
    this.$store.commit('setModulo', this.$route.name);
    this.$store.commit('setTrimestre', Number(this.$route.params.trimestre));
  }

  isGaceta(apartado: boolean) {
    this.resetAllStates();
    this.$store.commit('setApartado', 'gaceta');
    this.$store.commit('setModulo', this.$route.name);
    this.$store.commit('setTrimestre', Number(this.$route.params.trimestre));
  }
}
