













































import { Component, Inject, Ref, VModel, Vue } from 'vue-property-decorator';
import DocumentosLeyGeneralService from '@/shared/service/documentos-api.service';
import DocumentoBase64Service from '@/shared/service/documento-base64-api.service';

import AccountService from '@/account/account.service';
import { mapGetters } from 'vuex';
import DocumentoService from '@/shared/service/documento-api.service';
import EliminarDocumentoService from '@/shared/service/eliminar-documento-api.service';
import { Documento } from '@/shared/openapi-client';
import { BModal } from 'bootstrap-vue';

@Component({
  computed: {
    ...mapGetters([
      'articulo',
      'fraccion',
      'esInformacionPublica',
      'esInstanciaTecnicaEvaluacion',
      'documentos',
      'ejercicio',
      'modulo',
      'trimestre',
    ]),
  },
})
export default class LeyGeneral extends Vue {
  @Inject('documentosLeyGeneralService') documentosLeyGeneralService: () => DocumentosLeyGeneralService;
  @Inject('documentoBase64Service') documentoBase64Service: () => DocumentoBase64Service;
  @Inject('documentoService') documentoService: () => DocumentoService;
  @Inject('eliminarDocumentoService') eliminarDocumentoService: () => EliminarDocumentoService;

  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;

  @VModel({ required: true })
  documentos!: any[];

  ruta = '';

  readonly ejercicio!: number;

  readonly modulo!: string;

  readonly trimestre!: number;

  readonly esInformacionPublica!: boolean;

  readonly esInstanciaTecnicaEvaluacion!: boolean;

  readonly articulo!: number;

  readonly fraccion!: number;

  @Ref('modalCopiar')
  modalCopiar: BModal;

  moduloActual = '';

  perPage = 10;

  currentPage = 1;

  previousPage = 1;

  totalRows = 0;

  nombreDocumento = '';

  contenido = '';

  documentoURI = ''; // Declaración de la propiedad documentoURI como string

  documentoBase64 = '';

  rutaDominio: string = this.$t('ayuntamientos.tlapa.dominio') as string;

  isBusy = true;

  created() {
    this.totalRows = this.documentos.length;
  }

  copiarUrlDocumento(nombre: string, idDocumento: number) {
    this.$store.commit('setNombreDocumento', nombre);
    this.$store.commit('setIdDocumento', idDocumento);
    if (this.esInformacionPublica) {
      this.moduloActual = 'informacion-publica';
    } else if (this.esInstanciaTecnicaEvaluacion) {
      this.moduloActual = 'instancia-tecnica';
    } else {
      this.moduloActual = 'ley-general';
    }
    this.ruta =
      this.rutaDominio +
      this.moduloActual +
      '/' +
      this.ejercicio +
      '/' +
      this.modulo +
      '/' +
      this.trimestre +
      '/' +
      nombre +
      '/' +
      idDocumento +
      '/' +
      this.articulo +
      '/' +
      this.fraccion;
    this.modalCopiar.show();
  }

  cancelar() {
    this.modalCopiar.hide();
  }

  /**
   * Obtiene un documento en base 64 para mostrar.
   * Utiliza en serivio descargaDocumentoBase64.
   * @param documento recibe un documento selecionado del cuaĺ utiliza la clave y la extensión.
   * @returns un string base64
   */
  public verDocumento(doc: any) {
    this.$router.push({ name: 'documento', params: { idDocumento: doc.clave } }).catch(() => {});
  }

  /**
   * Obtiene la extensión del documento.
   * TODO: Usar librería para obtener las extensiones de todos los mime type.
   * Se agregan las posible combinaciones debido a que existen documento que se cargaron
   * anteriormente de los cuales sólo se cargó: pdf, xls o doc.
   * Después de la refactorización se guardarán los mimeType extraídos usando el objeto File.
   */
  public getLinkData(documento: any): string {
    if (documento.extension === 'xls') {
      return 'data:application/vnd.ms-excel;base64,';
    }
    if (documento.extension === 'xlsx') {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,base64,';
    }
    if (documento.extension === 'pdf') {
      return 'data:application/pdf;base64,';
    }
    if (documento.extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    }
    if (documento.extension === 'application/vnd.ms-excel') {
      return 'data:application/vnd.ms-excel;base64;base64,';
    }
    if (documento.extension === 'application/pdf') {
      return 'data:application/pdf;base64,';
    }
  }

  public getExtension(extension: string): string {
    switch (extension) {
      case 'application/vnd.ms-excel':
        return '.xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return '.xlsx';
      case 'application/pdf':
        return '.pdf';
      case 'pdf':
        return '.pdf';
      case 'xls':
        return '.xls';
    }
  }

  /**
   * Obtiene un documento en base 64 para mostrar.
   * Utiliza en serivio descargaDocumentoBase64.
   * @param documento recibe un documento selecionado del cuaĺ utiliza la clave y la extensión.
   * @returns un string base64
   */
  public descargaDocumento(doc: any) {
    try {
      this.documentoBase64Service()
        .obtenerDocumentoBase64(doc.clave)
        .then(res => {
          this.descargaDocumentoBase64(doc, res.data);
        });
    } catch (e) {
      console.log(e);
    }
  }

  private mostrarMensajeUsuario(toastTitle: string, message: string) {
    this.$bvToast.toast(message, {
      title: toastTitle,
      autoHideDelay: 5000,
      variant: 'success',
    });
  }

  private mensajeError(toastTitle: string, message: string) {
    this.$bvToast.toast(message, {
      title: toastTitle,
      autoHideDelay: 5000,
      variant: 'danger',
    });
  }

  /**
   * convierte base64 a un archivo para descargar.
   * @param documento recibe un documento selecionado del cuaĺ utiliza la clave y la extensión.
   * @param contenido el contenido en base 64
   * @returns un string base64
   */
  descargaDocumentoBase64(doc: any, contenido: string) {
    try {
      const uri = this.getLinkData(doc) + contenido;
      const downloadLink = document.createElement('a');
      const fileName = doc.nombre + this.getExtension(doc.extension);
      downloadLink.href = uri;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (e) {
      console.log(e);
    }
  }

  get canVer() {
    return this.$store.getters.account.authorities.includes('ROLE_ADMIN');
  }

  columnas: any = [
    {
      key: 'id',
      label: '#',
    },
    {
      key: 'nombre',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'extension',
      label: 'Extensión',
    },
    { key: 'actions', label: 'Acciones' },
  ];
}
