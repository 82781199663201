
























import { Component, Vue } from 'vue-property-decorator';
import { ARTICULO_81 } from '@/constants';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters(['articulo', 'fraccion', 'esInformacionPublica', 'documentos', 'ejercicio']),
  },
})
export default class Articulo81 extends Vue {
  columnas: any = [
    {
      key: 'id',
      label: 'Fracción',
    },
  ];

  arti81: any[] = [];

  readonly esInformacionPublica!: boolean;

  readonly articulo!: number;

  readonly fraccion!: number;

  readonly ejercicio!: number;

  created() {
    ARTICULO_81.forEach(a => {
      const art = {
        id: a.key,
        descripcion: a.label,
      };
      this.arti81.push(art);
    });
  }

  setEjercicio(ejercicio: string, fraccion: any) {
    this.$store.commit('setArticulo', 81);
    this.$store.commit('setFraccion', fraccion);
    this.$store.commit('setModulo', 'PRIMER');
    this.$store.commit('setTrimestre', 1);
    this.$store.commit('setEjercicio', ejercicio);
    this.$router
      .push({ name: 'EJERCICIO_' + ejercicio, params: { ejercicio: this.ejercicio.toString(10), general: 'iPublica' } })
      .catch(() => {});
  }
}
