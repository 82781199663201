



























import { Documento } from '@/shared/openapi-client';
import DocumentosLeyGeneralService from '@/shared/service/documentos-api.service';
import { Component, Inject, VModel, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import ActaCircunstanciada from './acta_circunstanciada.vue';
import ActaEntrega from './acta_entrega.vue';
import ActasOtras from './acta_otras.vue';

@Component({
  components: {
    'acta-circunstanciada': ActaCircunstanciada,
    'acta-entrega': ActaEntrega,
    'acta-otras': ActasOtras,
  },
  computed: {
    ...mapGetters(['modulo', 'apartado', 'ejercicio', 'trimestre']),
  },
})
export default class Actas extends Vue {
  @Inject('documentosLeyGeneralService') documentosLeyGeneralService: () => DocumentosLeyGeneralService;

  @VModel({ required: true })
  documentos!: Documento[];

  documentosTemp: any[] = [];

  documentosTemp1: any[] = [];

  readonly modulo!: string;

  readonly apartado!: string;

  readonly ejercicio!: number;

  readonly trimestre!: number;

  tipoDocumento = 'CONTRATO';

  @Watch('modulo', { immediate: true })
  consultar() {
    if (this.modulo === 'FAISM') {
      this.consultarDocumentos(1, this.tipoDocumento);
    }
  }

  consultarDocumentos(tipoFaism: number, tipoDocumento: string) {
    this.tipoDocumento = tipoDocumento;
    this.documentos = [];
    this.documentosTemp = [];
    let contador = 1;
    this.documentosLeyGeneralService()
      .obtenerDocumentos(this.apartado, this.modulo, this.ejercicio, this.trimestre, this.tipoDocumento)
      .then(res => {
        this.documentosTemp = res.data;
        procesarDocumentos();
        this.queActaEs(tipoFaism);
      });

    const procesarDocumentos = () => {
      this.documentosTemp.forEach(r => {
        const doc = {
          id: contador++,
          clave: r.id,
          nombre: r.nombre,
          extension: this.getExtension(r.mediaType),
          articulo: r.articulo,
          fraccion: r.fraccion,
          url: r.uri,
          contrato: r.contrato,
          acta: r.acta,
          instancia: r.instancia,
          modulo: r.modulo,
          trimestre: r.trimestre,
          finiquito: r.finiquito,
          cpublica: r.cuentaPublica,
          leyGeneral: r.leyGeneral,
          informacionPublica: r.informacionPublica,
          tipoActa: r.tipoActa,
          contenido: r.contenido,
        };

        this.documentos.push(doc);
      });
    };
  }

  public getExtension(extension: string): string {
    switch (extension) {
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/pdf':
        return 'pdf';
      case 'pdf':
        return 'pdf';
      case 'xls':
        return 'xls';
    }
  }

  queActaEs(tipo: number) {
    this.documentosTemp = [];
    this.documentosTemp1 = [];
    this.documentosTemp = this.documentos.filter(d => d.acta === true && d.tipoActa === tipo);
    let contador = 1;
    this.documentosTemp.forEach(r => {
      const doc = {
        id: contador++,
        clave: r.clave,
        nombre: r.nombre,
        extension: r.extension,
        url: r.url,
      };
      this.documentosTemp1.push(doc);
    });
    this.documentosTemp1.sort((a, b) => a - b);
  }
}
