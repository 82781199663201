// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import LoginService from './account/login.service';
import AccountService from './account/account.service';

// Servicios y configuración del API
import { Configuration } from './shared/openapi-client';

import '../content/scss/vendor.scss';
import TranslationService from '@/locale/translation.service';

import UserOAuth2Service from '@/entities/user/user.oauth2.service';
import UserApiService from './shared/service/user-api.service';
import PetApiService from './shared/service/pet-api.service';
import LeyApiService from './shared/service/ley-api.service';
import DocumentosLeyGeneralService from './shared/service/documentos-api.service';
import DocumentoService from './shared/service/documento-api.service';
import GuardaDocumentoService from './shared/service/guarda-documento-api.service';
import DocumentoBase64Service from './shared/service/documento-base64-api.service';
import DocumentosInformacionPublicaService from './shared/service/documentos-informacion-publica-api.service';
import DocumentosSevacService from './shared/service/documentos-sevac-api.service';
import DocumentosChuckService from './shared/service/documento-chuck-api.service';
import EliminarDocumentoService from './shared/service/eliminar-documento-api.service';
import DocumentoCompletoService from './shared/service/documento-completo-api.service';

/* tslint:disable */

// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const loginService = new LoginService();

// Configuración para el api del back
export const apiConfiguration = new Configuration({
  basePath: 'api',
});

const accountService = new AccountService(store, translationService, router);

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/not-found');
  }

  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/forbidden');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */

new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),
    userOAuth2Service: () => new UserOAuth2Service(),
    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
    userApiService: () => new UserApiService(apiConfiguration),
    petApiService: () => new PetApiService(apiConfiguration),
    leyApiService: () => new LeyApiService(apiConfiguration),
    documentosLeyGeneralService: () => new DocumentosLeyGeneralService(apiConfiguration),
    documentosInformacionPublicaService: () => new DocumentosInformacionPublicaService(apiConfiguration),
    documentoService: () => new DocumentoService(apiConfiguration),
    guardaDocumentoService: () => new GuardaDocumentoService(apiConfiguration),
    documentoBase64Service: () => new DocumentoBase64Service(apiConfiguration),
    documentosSevacService: () => new DocumentosSevacService(apiConfiguration),
    documentosChuckService: () => new DocumentosChuckService(apiConfiguration),
    eliminarDocumentoService: () => new EliminarDocumentoService(apiConfiguration),
    documentoCompletoService: () => new DocumentoCompletoService(apiConfiguration),
  },
  i18n,
  store,
});

/*
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    loginService: () => loginService,
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userService: () => new UserManagementService(),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    userOAuth2Service: () => new UserOAuth2Service(),
    translationService: () => translationService,
    // jhipster-needle-add-entity-service-to-main - JHipster will import entities services here
    accountService: () => accountService,
  },
  i18n,
  store,
});*/
