const MisionVision = () => import('@/mision-vision/mision-vision.vue');
const Base = () => import('@/shared/base/base.vue');
const TramitesServicios = () => import('@/modulos/tramites-servicios/tramites-servicios.vue');
const AvisosPrivacidad = () => import('@/avisos-privacidad/avisos-privacidad.vue');

export default [
  {
    path: '/ley-general',
    name: 'Ley_General',
    component: Base,
  },
  {
    path: '/ley-disciplina',
    name: 'Ley_Disciplina',
    component: Base,
  },
  {
    path: '/sevac',
    name: 'Sevac',
    component: Base,
  },
  {
    path: '/gaceta-municipal',
    name: 'Gaceta_Municipal',
    component: Base,
  },
  {
    path: '/mision-vision',
    name: 'MisionVision',
    component: MisionVision,
  },
  {
    path: '/instancia-tecnica-evaluacion/',
    name: 'InstanciaTecnicaEvaluacion',
    component: Base,
  },
  {
    path: '/avisos-privacidad',
    name: 'AvisosPrivacidad',
    component: AvisosPrivacidad,
  },
  {
    path: '/tramites-servicios',
    name: 'TramitesServicios',
    component: TramitesServicios,
  },
];
