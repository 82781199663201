var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c(
      "div",
      [
        _c("b-container", [
          _c(
            "div",
            [
              _c(
                "b-tabs",
                { attrs: { card: "" } },
                [_c("b-tab", [_c("div", [_c("ejercicios")], 1)])],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }