import Vue from 'vue';
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import Router, { RouteConfig } from 'vue-router';
import VueRouter from 'vue-router';

const HomeGeneral = () => import('@/core/home/home-general.vue');

const Error = () => import('@/core/error/error.vue');
import account from '@/router/account';
import admin from '@/router/admin';
import entities from '@/router/entities';
import pages from '@/router/pages';
import modules from '@/router/modules';
const InformacionPublica = () => import('@/informacion-publica/informacion-publica.vue');
const Articulo81 = () => import('@/informacion-publica/articulos/articulo81.vue');
const Articulo82 = () => import('@/informacion-publica/articulos/articulo82.vue');
const Articulo85 = () => import('@/informacion-publica/articulos/articulo85.vue');
const Ejercicios = () => import('@/ejercicios/ejercicios.vue');
const Ejercicio2024 = () => import('@/ejercicios/ejercicio-2024.vue');
const Ejercicio2023 = () => import('@/ejercicios/ejercicio-2023.vue');
const Ejercicio2022 = () => import('@/ejercicios/ejercicio-2022.vue');
const Ejercicio2021 = () => import('@/ejercicios/ejercicio-2021.vue');
const Ejercicio2020 = () => import('@/ejercicios/ejercicio-2022.vue');
const Ejercicio2019 = () => import('@/ejercicios/ejercicio-2019.vue');
const Ejercicio2018 = () => import('@/ejercicios/ejercicio-2018.vue');
const PrimerTrimestre = () => import('@/modulos/primer-trimestre/primer-trimestre.vue');
const SegundoTrimestre = () => import('@/modulos/segundo-trimestre/segundo-trimestre.vue');
const TercerTrimestre = () => import('@/modulos/tercer-trimestre/tercer-trimestre.vue');
const CuartoTrimestre = () => import('@/modulos/cuarto-trimestre/cuarto-trimestre.vue');
const ANUAL = () => import('@/modulos/anual/anual.vue');
const SEMESTRAL = () => import('@/modulos/semestral/semestral.vue');
const CPUBLICA = () => import('@/modulos/cuenta-publica/cuenta-publica.vue');
const FAISM = () => import('@/modulos/faism/faism.vue');
const Base = () => import('@/shared/base/base.vue');
const Documento = () => import('@/modulos/documento/documento.vue');
const DocumentoBase64 = () => import('@/modulos/documento/ver-documento-base64.vue');
const LoginForm = () => import('@/account/login-form/login-form.vue');
const AdministrarDocumentos = () => import('@/modulos/seleccionar-documentos/administrar-documentos.vue');
const SeleccionarDocumento = () => import('@/modulos/seleccionar-documentos/seleccionar-documentos.vue');

Vue.use(Router);

// prettier-ignore
const routes: Array<RouteConfig> = [
    {
      path: '/forbidden',
      name: 'Forbidden',
      component: Error,
      meta: { error403: true }
    },
    {
      path: '/not-found',
      name: 'NotFound',
      component: Error,
      meta: { error404: true }
    },
    {
      path: '/',
      name: 'HomeGeneral',
      component: HomeGeneral
    },
    {
      path: '/administrar-documentos/:accion/',
      name: 'AdministrarDocumentos',
      component: AdministrarDocumentos,
      meta: {
        can: 'ayuntamiento',
      },
    },
    {
      path: '/login',
      name: 'LoginForm',
      component: LoginForm
    },
    {
      path: '/informacion-publica/',
      component: InformacionPublica,
      children: [
        {
          path: 'articulo-81',
          name: 'ARTICULO_81',
          component: Articulo81,
        },
        {
          path: 'articulo-82',
          name: 'ARTICULO_82',
          component: Articulo82,
        },
        {
          path: 'articulo-85',
          name: 'ARTICULO_85',
          component: Articulo85,
        }
      ],
    },
    {
      path: '/',
      component: Base,
      children: [
        {
          path: 'ejercicio-2018',
          name: 'EJERCICIO_2018',
          component: Ejercicio2018,
        },
        {
          path: 'ejercicio-2019',
          name: 'EJERCICIO_2019',
          component: Ejercicio2019,
        },
        {
          path: 'ejercicio-2020',
          name: 'EJERCICIO_2020',
          component: Ejercicio2020,
        },
        {
          path: 'ejercicio-2021',
          name: 'EJERCICIO_2021',
          component: Ejercicio2021,
        },
        {
          path: 'ejercicio-2022',
          name: 'EJERCICIO_2022',
          component: Ejercicio2022,
        },
        {
          path: 'ejercicio-2023',
          name: 'EJERCICIO_2023',
          component: Ejercicio2023,
        },
        {
          path: 'ejercicio-2024',
          name: 'EJERCICIO_2024',
          component: Ejercicio2024,
        },
      ],
    },
    {
      path: '/:apartado/:ejercicio/:trimestre/:pesta',
      component: Base,
      children: [
        {
          path: 'PRIMER',
          name: 'PRIMER',
          component: PrimerTrimestre,
        },
        {
          path: 'SEGUNDO',
          name: 'SEGUNDO',
          component: SegundoTrimestre,
        },
        {
          path: 'TERCER',
          name: 'TERCER',
          component: TercerTrimestre,
        },
        {
          path: 'CUARTO',
          name: 'CUARTO',
          component: CuartoTrimestre,
        },
        {
          path: 'ANUAL',
          name: 'ANUAL',
          component: ANUAL,
        },
        {
          path: 'FAISM',
          name: 'FAISM',
          component: FAISM,
        },
        {
          path: 'SEMESTRAL',
          name: 'SEMESTRAL',
          component: SEMESTRAL,
        },
        {
          path: 'CPUBLICA',
          name: 'CPUBLICA',
          component: CPUBLICA,
        },
      ],
    },
    {
      path: '/:general/:ejercicio/:modulo/:trimestre/:nombre/:clave/:articulo/:fraccion',
      component: Documento,
      children: [
        {
          path: 'verDocumento',
          name: 'Documento',
          component: Documento,
        }
      ],
    },
    {
      path: '/ver-documento/:idDocumento',
      component: DocumentoBase64,
      children: [
        {
          path: 'documento',
          name: 'documento',
          component: DocumentoBase64,
        }
      ],
    },
    ...account,
    ...admin,
    ...entities,
    ...pages,
    ...modules
  ];

// prettier-ignore
export default new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});
