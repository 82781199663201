var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c(
      "div",
      [
        _c("h3", [
          _vm._v(
            "Ley Número 207 de Transparencia y Acceso a la Información Pública del Estado de Guerrero"
          )
        ]),
        _vm._v(" "),
        _c(
          "b-card",
          { attrs: { "no-body": "" } },
          [
            _c(
              "b-tabs",
              { attrs: { card: "" } },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Artículo 81" },
                    on: {
                      click: function($event) {
                        return _vm.setArticulo("81")
                      }
                    }
                  },
                  [_c("articulo-81")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Artíclo 82" },
                    on: {
                      click: function($event) {
                        return _vm.setArticulo("82")
                      }
                    }
                  },
                  [_c("articulo-82")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Artículo 85" },
                    on: {
                      click: function($event) {
                        return _vm.setArticulo("85")
                      }
                    }
                  },
                  [_c("articulo-85")],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }