














import Vue from 'vue';
import Component from 'vue-class-component';

import '@/shared/config/dayjs';

@Component({})
export default class App extends Vue {
  sidebarOpen: boolean;

  created() {
    this.sidebarOpen = true;
  }
}
