
































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  computed: {
    ...mapGetters(['seleccionarDocumento']),
  },
})
export default class JhiFooter extends Vue {
  rutaTransparencia = 'https://www.plataformadetransparencia.org.mx/';
  rutaCurp = 'https://www.gob.mx/curp/';
  rutaInai = 'https://home.inai.org.mx/';

  readonly seleccionarDocumento;
  created() {}
}
