







import { Component, Vue } from 'vue-property-decorator';
import Modulos from '@/modulos/modulos.vue';

@Component({
  components: {
    modulos: Modulos,
  },
})
export default class Ejercicio2023 extends Vue {
  ejercicio2024 = 2024;
  created() {}
}
