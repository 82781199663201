








import { Component, Vue } from 'vue-property-decorator';
import Modulos from '@/modulos/modulos.vue';

@Component({
  components: {
    modulos: Modulos,
  },
})
export default class Ejercicio2021 extends Vue {
  ejercicio2021 = 2021;
}
