var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-tabs",
            {
              model: {
                value: _vm.activeTab,
                callback: function($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab"
              }
            },
            _vm._l(_vm.tabs, function(tab, index) {
              return _c(
                "b-tab",
                {
                  key: tab.name,
                  attrs: { title: tab.title },
                  on: {
                    click: function() {
                      return _vm.setModulo(tab.name, tab.trimestre, index)
                    }
                  }
                },
                [
                  _c(
                    "b-card",
                    [
                      _c(tab.component, {
                        tag: "component",
                        on: {
                          "consulta-faism": function($event) {
                            return _vm.setModulo(tab.name, tab.trimestre, index)
                          }
                        },
                        model: {
                          value: _vm.documentos,
                          callback: function($$v) {
                            _vm.documentos = $$v
                          },
                          expression: "documentos"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }