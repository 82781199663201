
















































import { Component, Vue } from 'vue-property-decorator';
import Ejercicio2024 from '@/ejercicios/ejercicio-2024.vue';
import Ejercicio2023 from '@/ejercicios/ejercicio-2023.vue';
import Ejercicio2022 from '@/ejercicios/ejercicio-2022.vue';
import Ejercicio2021 from '@/ejercicios/ejercicio-2021.vue';
import Ejercicio2020 from '@/ejercicios/ejercicio-2020.vue';
import Ejercicio2019 from '@/ejercicios/ejercicio-2019.vue';
import Ejercicio2018 from '@/ejercicios/ejercicio-2018.vue';
import { mapGetters } from 'vuex';

@Component({
  components: {
    'ejercicio-2024': Ejercicio2024,
    'ejercicio-2023': Ejercicio2023,
    'ejercicio-2022': Ejercicio2022,
    'ejercicio-2021': Ejercicio2021,
    'ejercicio-2020': Ejercicio2020,
    'ejercicio-2019': Ejercicio2019,
    'ejercicio-2018': Ejercicio2018,
  },
  computed: {
    ...mapGetters(['ejercicio']),
  },
})
export default class Ejercicios extends Vue {
  tabEjercicio = 0;

  ejercicio!: number;

  created() {
    this.$store.commit('setEjercicio', Number(this.$route.params.ejercicio));
    if (this.ejercicio === 2018) {
      this.tabEjercicio = 6;
    } else if (this.ejercicio === 2019) {
      this.tabEjercicio = 5;
    } else if (this.ejercicio === 2020) {
      this.tabEjercicio = 4;
    } else if (this.ejercicio === 2021) {
      this.tabEjercicio = 3;
    } else if (this.ejercicio === 2022) {
      this.tabEjercicio = 2;
    } else if (this.ejercicio === 2023) {
      this.tabEjercicio = 1;
    } else if (this.ejercicio === 2024) {
      this.tabEjercicio = 0;
    }
  }

  private enrutamiento(ejercicio: string) {
    this.$store.commit('setEjercicio', Number(ejercicio));
    this.$router.push({ name: 'EJERCICIO_' + ejercicio, params: {} });
  }
}
