var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-container", [
    _c(
      "div",
      [
        _c("b-container", [
          _c(
            "div",
            [
              _c(
                "b-tabs",
                {
                  model: {
                    value: _vm.tabEjercicio,
                    callback: function($$v) {
                      _vm.tabEjercicio = $$v
                    },
                    expression: "tabEjercicio"
                  }
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2024" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2024")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2024")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2023" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2023")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2023")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2022" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2022")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2022")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2021" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2021")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2021")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2020" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2020")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2020")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2019" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2019")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2019")], 1)]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-tab",
                    {
                      attrs: { title: "2018" },
                      on: {
                        click: function($event) {
                          return _vm.enrutamiento("2018")
                        }
                      }
                    },
                    [_c("div", [_c("ejercicio-2018")], 1)]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }