







import { Component, Vue } from 'vue-property-decorator';
import Modulos from '@/modulos/modulos.vue';

@Component({
  components: {
    modulos: Modulos,
  },
})
export default class Ejercicio2022 extends Vue {
  ejercicio2022 = 2022;
  created() {}
}
