var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _vm.seleccionarDocumento
        ? _c(
            "b-nav",
            { staticClass: "d-flex justify-content-between flex-wrap" },
            [
              _c("b-nav-item", { attrs: { href: _vm.rutaTransparencia } }, [
                _c(
                  "div",
                  { attrs: { title: "Solicitud en línea" } },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn btn-icon btn-round mr-1",
                        attrs: { href: _vm.rutaTransparencia, target: "_blank" }
                      },
                      [
                        _c("span", { staticClass: "text-dark" }, [
                          _vm._v("Consulta Pública")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: _vm.rutaCurp } }, [
                _c(
                  "div",
                  { attrs: { title: "Consulta tu CURP" } },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn btn-icon btn-round mr-1",
                        attrs: { href: _vm.rutaCurp, target: "_blank" }
                      },
                      [
                        _c("span", { staticClass: "text-dark" }, [
                          _vm._v("Consulta tu CURP")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { href: _vm.rutaInai } }, [
                _c(
                  "div",
                  { attrs: { title: "Consulta tu CURP" } },
                  [
                    _c(
                      "b-link",
                      {
                        staticClass: "btn btn-icon btn-round mr-1",
                        attrs: { href: _vm.rutaInai, target: "_blank" }
                      },
                      [
                        _c("span", { staticClass: "text-dark" }, [
                          _vm._v("INAI")
                        ])
                      ]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [_c("br")]),
      _vm._v(" "),
      _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
        _c("p", {
          domProps: { textContent: _vm._s(_vm.$t("ayuntamientos.tlapa.aviso")) }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }