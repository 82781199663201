var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "modalCopiar",
          attrs: {
            title: "Copiar ruta",
            size: "md",
            id: "modalCopiar",
            "no-close-on-esc": "",
            "no-close-on-backdrop": "",
            "ok-only": "",
            scrollable: ""
          },
          on: {
            close: function($event) {
              return _vm.cancelar()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "w-100" },
            [_c("b-link", [_vm._v(" " + _vm._s(_vm.ruta))])],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("b-table", {
        attrs: {
          id: "documento",
          items: _vm.documentos,
          fields: _vm.columnas,
          "sticky-header": "",
          "head-variant": "light",
          responsive: "",
          "show-empty": ""
        },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function(row) {
              return [
                _c("div", { staticClass: "wrapper-toolbar" }, [
                  row.item.url !== "pendiente"
                    ? _c(
                        "a",
                        {
                          staticClass: "btn btn-icon btn-round mr-1",
                          attrs: { href: row.item.url, target: "_blank" }
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "eye-fill",
                              variant: "primary",
                              title: "Descargar documento"
                            },
                            on: {
                              click: function($event) {
                                return _vm.descargaDocumento(row.item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-icon btn-round" },
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "cursor",
                          variant: "primary",
                          title: "Copiar ruta"
                        },
                        on: {
                          click: function($event) {
                            return _vm.copiarUrlDocumento(
                              row.item.nombre,
                              row.item.clave
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "table-busy",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "text-center text-info my-2" },
                  [
                    _c("b-spinner", { staticClass: "align-middle" }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Cargando...")])
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "empty",
            fn: function() {
              return [
                _c("h4", [_vm._v("No existen documentos para este módulo")])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }