var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-container", [
        _c(
          "div",
          [
            _c(
              "b-card",
              [
                _c(
                  "b-tabs",
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { title: "Primer Semestre" },
                        on: {
                          click: function($event) {
                            return _vm.setTiempo(16, 0)
                          }
                        }
                      },
                      [
                        _c(
                          "b-card",
                          [
                            _c("primer-semestre", {
                              model: {
                                value: _vm.documentos,
                                callback: function($$v) {
                                  _vm.documentos = $$v
                                },
                                expression: "documentos"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-tab",
                      {
                        attrs: { title: "Segundo Semestre" },
                        on: {
                          click: function($event) {
                            return _vm.setTiempo(26, 1)
                          }
                        }
                      },
                      [
                        _c(
                          "b-card",
                          [
                            _c("segundo-semestre", {
                              model: {
                                value: _vm.documentos,
                                callback: function($$v) {
                                  _vm.documentos = $$v
                                },
                                expression: "documentos"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }