import { render, staticRenderFns } from "./tabla-general.vue?vue&type=template&id=712aadd8&"
import script from "./tabla-general.vue?vue&type=script&lang=ts&"
export * from "./tabla-general.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/comimsa_fco/Videos/hayuntamiento/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('712aadd8')) {
      api.createRecord('712aadd8', component.options)
    } else {
      api.reload('712aadd8', component.options)
    }
    module.hot.accept("./tabla-general.vue?vue&type=template&id=712aadd8&", function () {
      api.rerender('712aadd8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/tabla-general/tabla-general.vue"
export default component.exports