var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle.sidebar-border",
              modifiers: { "sidebar-border": true }
            }
          ]
        },
        [_vm._v("Toggle Sidebar")]
      ),
      _vm._v(" "),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "sidebar-border",
            "sidebar-class": "border-right border-danger"
          }
        },
        [
          _c(
            "div",
            { staticClass: "px-3 py-2" },
            [
              _c("p", [
                _vm._v(
                  "\n        Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus,\n        porta ac consectetur ac, vestibulum at eros.\n      "
                )
              ]),
              _vm._v(" "),
              _c("b-img", {
                attrs: {
                  src: "https://picsum.photos/500/500/?image=54",
                  fluid: "",
                  thumbnail: ""
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }