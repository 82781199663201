import { Documento } from '@/shared/openapi-client';
import { Module } from 'vuex';

export const ayuntamientoStore: Module<any, any> = {
  state: {
    articulo: null,
    fraccion: null,
    esInformacionPublica: false,
    documentos: [],
    esInstanciaTecnicaEvaluacion: false,
    modulo: null,
    trimestre: null,
    ejercicio: null,
    nombreDocumento: null,
    idDocumento: null,
    esLeyGeneral: null,
    seleccionarDocumento: null,
    apartado: null,
    esSevac: null,
    esLeyDisciplina: null,
    esGaceta: null,
  },
  getters: {
    articulo: state => state.articulo,
    fraccion: state => state.fraccion,
    esInformacionPublica: state => state.esInformacionPublica,
    documentos: state => state.documentos,
    esInstanciaTecnicaEvaluacion: state => state.esInstanciaTecnicaEvaluacion,
    modulo: state => state.modulo,
    trimestre: state => state.trimestre,
    ejercicio: state => state.ejercicio,
    nombreDocumento: state => state.nombreDocumento,
    idDocumento: state => state.idDocumento,
    esLeyGeneral: state => state.esLeyGeneral,
    seleccionarDocumento: state => state.seleccionarDocumento,
    apartado: state => state.apartado,
    esSevac: state => state.esSevac,
    esLeyDisciplina: state => state.esLeyDisciplina,
    esGaceta: state => state.esGaceta,
  },
  mutations: {
    setArticulo(state, articulo) {
      state.articulo = articulo;
    },
    setFraccion(state, fraccion) {
      state.fraccion = fraccion;
    },
    setModulo(state, modulo) {
      state.modulo = modulo;
    },
    setTrimestre(state, trimestre) {
      state.trimestre = trimestre;
    },
    setEjercicio(state, ejercicio) {
      state.ejercicio = ejercicio;
    },
    setNombreDocumento(state, nombreDocumento) {
      state.nombreDocumento = nombreDocumento;
    },
    setEsInformacionPublica(state, esInformacionPublica) {
      state.esInformacionPublica = esInformacionPublica;
    },
    setEsInstanciaTecnicaEvaluacion(state, esInstanciaTecnicaEvaluacion) {
      state.esInstanciaTecnicaEvaluacion = esInstanciaTecnicaEvaluacion;
    },
    setDocumentos(state, documentos: Array<Documento>) {
      if (documentos) state.documentos = documentos;
    },
    setIdDocumento(state, idDocumento) {
      state.idDocumento = idDocumento;
    },
    setEsLeyGeneral(state, esLeyGeneral) {
      state.esLeyGeneral = esLeyGeneral;
    },
    setSeleccionarDocumento(state, seleccionarDocumento) {
      state.seleccionarDocumento = seleccionarDocumento;
    },
    setApartado(state, apartado) {
      state.apartado = apartado;
    },
    setEsSevac(state, esSevac) {
      state.esSevac = esSevac;
    },
    setEsLeyDisciplina(state, esLeyDisciplina) {
      state.esLeyDisciplina = esLeyDisciplina;
    },
    setEsGaceta(state, esGaceta) {
      state.esGaceta = esGaceta;
    },
  },
  actions: {
    accountStore(ctx) {
      ctx.commit('setArticulo', null);
      ctx.commit('setFraccion', null);
      ctx.commit('setDocumentos', []);
      ctx.commit('setModulo', null);
      ctx.commit('setEjercicio', null);
      ctx.commit('setTrimestre', null);
      ctx.commit('setNombreDocumento', null);
      ctx.commit('setIdDocumento', null);
      ctx.commit('setEsLeyGeneral', null);
      ctx.commit('setSeleccionarDocumento', null);
      ctx.commit('setApartado', null);
      ctx.commit('setSevac', null);
      ctx.commit('setEsLeyDisciplina', null);
      ctx.commit('setGaceta', null);
    },
  },
};
