








import { Component, Vue } from 'vue-property-decorator';
import Modulos from '@/modulos/modulos.vue';

@Component({
  components: {
    modulos: Modulos,
  },
})
export default class Ejercicio2019 extends Vue {
  ejercicio2019 = 2019;
}
