






















import { Component, Vue } from 'vue-property-decorator';
import { ARTICULO_82 } from '@/constants';

@Component({})
export default class Articulo82 extends Vue {
  columnas: any = [
    {
      key: 'id',
      label: 'Fracción',
    },
  ];

  arti82: any[] = [];

  created() {
    ARTICULO_82.forEach(a => {
      const art = {
        id: a.key,
        descripcion: a.label,
      };
      this.arti82.push(art);
    });
  }

  private setEjercicio(ejercicio: string, fraccion: any) {
    this.$store.commit('setArticulo', 82);
    this.$store.commit('setFraccion', fraccion);
    this.$store.commit('setModulo', 'PRIMER');
    this.$store.commit('setTrimestre', 1);
    this.$router.push({ name: 'EJERCICIO_' + ejercicio, params: { general: 'iPublica' } }).catch(() => {});
  }
}
