var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("table", { staticClass: "table table-striped table-bordered" }, [
      _vm._m(1),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.arti85, function(art) {
          return _c("tr", { key: art.id }, [
            _c(
              "td",
              [
                _c(
                  "b-link",
                  {
                    on: {
                      click: function($event) {
                        return _vm.setEjercicio("2022", art.id)
                      }
                    }
                  },
                  [_vm._v(_vm._s(art.descripcion))]
                )
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "p-3 text-center" }, [
      _c("a", [
        _vm._v(
          "\n      Adicionalmente de lo señalado en los artículos 81 y 82 de la presente Ley, los Ayuntamientos deberán poner a disposición del público\n      y actualizar la información siguiente:"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [_c("th", [_vm._v("Fracciones")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }