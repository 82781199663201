





















import { Component, Vue } from 'vue-property-decorator';
import Articulo81 from '@/informacion-publica/articulos/articulo81.vue';
import Articulo82 from '@/informacion-publica/articulos/articulo82.vue';
import Articulo85 from '@/informacion-publica/articulos/articulo85.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';

@Component({
  components: {
    'articulo-81': Articulo81,
    'articulo-82': Articulo82,
    'articulo-85': Articulo85,
  },
  ...mapGetters(['articulo', 'fraccion', 'esInformacionPublica', 'ejercicio']),
})
export default class InformacionPublica extends Vue {
  ejercicioGeneral = 2022;

  public authenticationError = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;
  esInformacionPublica: boolean;

  readonly articulo!: number;

  readonly fraccion!: number;

  readonly ejercicio!: number;

  created() {
    this.$store.commit('setEjercicio', 2023);
  }

  setArticulo(articulo: string) {
    this.$store.commit('setArticulo', 81);
    this.$store.commit('setFraccion', this.fraccion);
    this.$store.commit('setEjercicio', this.ejercicio);
    this.$router.push({ name: 'ARTICULO_' + articulo, params: { ejercicio: this.ejercicio.toString(10) } }).catch(() => {});
  }
}
