var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      style: { backgroundColor: _vm.colores },
      attrs: { "data-cy": "navbar", toggleable: "sm" }
    },
    [
      _c("b-navbar-brand", { staticClass: "logo" }, [
        _c("span", { staticClass: "logo-img" }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "navbar-title",
            domProps: {
              textContent: _vm._s(_vm.$t("ayuntamientos.tlapa.nombre"))
            }
          },
          [_vm._v("Ayuntamiento")]
        )
      ]),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation"
          }
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("INICIO")))])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  attrs: {
                    "right-align": "",
                    id: "entity-menu",
                    "active-class": "active",
                    "data-cy": "entity"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _c("font-awesome-icon", {
                            attrs: { icon: "th-list" }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "no-bold" }, [
                            _vm._v("TRANSPARENCIA")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy": "Ley_General",
                        to: "/ley-general",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esLeyGeneral(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "book" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("LEY GENERAL DE CONTABILIDAD GUBERNAMENTAL")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy": "informacion-publica",
                        to: "/informacion-publica",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esInformacionPublica(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "users" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("INFORMACIÓN PÚBLICA")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy":
                          "INSTANCIA TECNICA DE EVALUACION DEL DESEMPEÑO",
                        to: "/instancia-tecnica-evaluacion",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esInstancia(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "pencil-alt" }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v("INSTANCIA TÉCNICA DE EVALUACIÓN DEL DESEMPEÑO")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy": "SEVAC",
                        to: "/sevac",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esSevac(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("SEVAC")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy": "Ley_Disciplina",
                        to: "/ley-disciplina",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esLeyDisciplina(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "book" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "LEY DE DISCIPLINA FINANCIERA DE LAS ENTIDADES FEDERATIVAS Y LOS MUNICIPIOS"
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-dropdown-item",
                    {
                      attrs: {
                        "data-cy": "Gaceta Municipal",
                        to: "/gaceta-municipal",
                        tag: "b-dropdown-item",
                        "active-class": "active"
                      },
                      on: {
                        click: function($event) {
                          return _vm.esGaceta(true)
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("GACETA MUNICIPAL")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "book" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("GOBIERNO")))])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { attrs: { to: "/tramites-servicios", exact: "" } },
                [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("Trámites y Servicios")))
                      ])
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "th-list" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Eventos")))])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "users" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Sala de Prensa")))])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("b-nav-item", { attrs: { to: "/mision-vision", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "wrench" } }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("Misión y Visión")))])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "b-nav-item",
                { attrs: { to: "/avisos-privacidad", exact: "" } },
                [
                  _c("span", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("Avisos de privacidad")))])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }