import { render, staticRenderFns } from "./acta_circunstanciada.vue?vue&type=template&id=996ad4fe&"
import script from "./acta_circunstanciada.vue?vue&type=script&lang=ts&"
export * from "./acta_circunstanciada.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/comimsa_fco/Videos/hayuntamiento/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('996ad4fe')) {
      api.createRecord('996ad4fe', component.options)
    } else {
      api.reload('996ad4fe', component.options)
    }
    module.hot.accept("./acta_circunstanciada.vue?vue&type=template&id=996ad4fe&", function () {
      api.rerender('996ad4fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/modulos/faism/actas/acta_circunstanciada.vue"
export default component.exports