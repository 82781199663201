/* tslint:disable */
/* eslint-disable */
/**
 * Swagger Petstore - OpenAPI 3.0
 * This is a sample Pet Store Server based on the OpenAPI 3.0 specification.  You can find out more about Swagger at [http://swagger.io](http://swagger.io). In the third iteration of the pet store, we\'ve switched to the design first approach! You can now help us improve the API whether it\'s by making changes to the definition itself or to the code. That way, with time, we can improve the API in general, and expose some of the new features in OAS3.  Some useful links: - [The Pet Store repository](https://github.com/swagger-api/swagger-petstore) - [The source API definition for the Pet Store](https://github.com/swagger-api/swagger-petstore/blob/master/src/main/resources/openapi.yaml)
 *
 * The version of the OpenAPI document: 1.0.6-SNAPSHOT
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
    /**
     * 
     * @type {number}
     * @memberof ApiResponse
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResponse
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface Ayuntamiento
 */
export interface Ayuntamiento {
    /**
     * id.
     * @type {string}
     * @memberof Ayuntamiento
     */
    id?: string;
    /**
     * Nombre del ayuntamiento.
     * @type {string}
     * @memberof Ayuntamiento
     */
    nombre?: string;
    /**
     * Dirección del ayuntamiento.
     * @type {string}
     * @memberof Ayuntamiento
     */
    direccion?: string;
    /**
     * Teléfono del ayuntamiento.
     * @type {string}
     * @memberof Ayuntamiento
     */
    telefono?: string;
    /**
     * Correo electrónico del ayuntamiento.
     * @type {string}
     * @memberof Ayuntamiento
     */
    correo?: string;
    /**
     * Valor hexadecimal para establecer el color de la página.
     * @type {string}
     * @memberof Ayuntamiento
     */
    colores?: string;
    /**
     * Misión del municipio.
     * @type {string}
     * @memberof Ayuntamiento
     */
    mision?: string;
    /**
     * Visión del municipio.
     * @type {string}
     * @memberof Ayuntamiento
     */
    vision?: string;
    /**
     * Dominio del municipio.
     * @type {string}
     * @memberof Ayuntamiento
     */
    dominio?: string;
    /**
     * Texto en el footer del sitio.
     * @type {string}
     * @memberof Ayuntamiento
     */
    footer?: string;
    /**
     * Nombre corto del municipio.
     * @type {string}
     * @memberof Ayuntamiento
     */
    nombreWeb?: string;
    /**
     * Total de imágenes disponibles para mostrar.
     * @type {number}
     * @memberof Ayuntamiento
     */
    totalImagenes?: number;
}
/**
 * 
 * @export
 * @interface Documento
 */
export interface Documento {
    /**
     * La clave del documento (URI, ID).
     * @type {number}
     * @memberof Documento
     */
    id?: number;
    /**
     * Ejercicio fiscal.
     * @type {number}
     * @memberof Documento
     */
    ejercicio?: number;
    /**
     * El nombre del archivo.
     * @type {string}
     * @memberof Documento
     */
    nombre?: string;
    /**
     * URL del documento.
     * @type {string}
     * @memberof Documento
     */
    uri?: string;
    /**
     * La fecha y hora que fue cargado el archivo.
     * @type {string}
     * @memberof Documento
     */
    fechaHora?: string;
    /**
     * El tipo MIME del contenido del documento.
     * @type {string}
     * @memberof Documento
     */
    mediaType?: string;
    /**
     * Modulo al que pertenece el documento.
     * @type {string}
     * @memberof Documento
     */
    modulo?: string;
    /**
     * Trimestre al que perteneces el documento.
     * @type {number}
     * @memberof Documento
     */
    trimestre?: number;
    /**
     * Articulo de información pública.
     * @type {number}
     * @memberof Documento
     */
    articulo?: number;
    /**
     * Fracción del artículo de información pública.
     * @type {number}
     * @memberof Documento
     */
    fraccion?: number;
    /**
     * Es tipo contrato.
     * @type {boolean}
     * @memberof Documento
     */
    contrato?: boolean;
    /**
     * Es tipo acta.
     * @type {boolean}
     * @memberof Documento
     */
    acta?: boolean;
    /**
     * Es instancia pública.
     * @type {boolean}
     * @memberof Documento
     */
    instancia?: boolean;
    /**
     * Es finiquito.
     * @type {boolean}
     * @memberof Documento
     */
    finiquito?: boolean;
    /**
     * Es srft.
     * @type {boolean}
     * @memberof Documento
     */
    srft?: boolean;
    /**
     * Es cuenta pública.
     * @type {boolean}
     * @memberof Documento
     */
    cuentaPublica?: boolean;
    /**
     * Es ley general.
     * @type {boolean}
     * @memberof Documento
     */
    leyGeneral?: boolean;
    /**
     * Es información pública.
     * @type {boolean}
     * @memberof Documento
     */
    informacionPublica?: boolean;
    /**
     * Tipo de acta.
     * @type {number}
     * @memberof Documento
     */
    tipoActa?: number;
    /**
     * Contenido base 64.
     * @type {string}
     * @memberof Documento
     */
    contenido?: string;
    /**
     * .
     * @type {string}
     * @memberof Documento
     */
    apartado?: string;
    /**
     * Peso en kb.
     * @type {number}
     * @memberof Documento
     */
    peso?: number;
    /**
     * qué tipo de Faism es.
     * @type {number}
     * @memberof Documento
     */
    tipoFaism?: number;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * 
     * @type {Documento}
     * @memberof InlineObject
     */
    documento: Documento;
    /**
     * 
     * @type {number}
     * @memberof InlineObject
     */
    totalPartes: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject
     */
    numeroParte: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Modulos {
    Trimestre = 'TRIMESTRE',
    Faism = 'FAISM',
    Capitulo = 'CAPITULO',
    Anual = 'ANUAL',
    Semestral = 'SEMESTRAL',
    Cuentapublica = 'CUENTAPUBLICA'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phone?: string;
    /**
     * User Status
     * @type {number}
     * @memberof User
     */
    userStatus?: number;
}

/**
 * AyuntamientoApi - axios parameter creator
 * @export
 */
export const AyuntamientoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eliminarDocumento', 'id', id)
            const localVarPath = `/eliminar-documento/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Guarda Información del ayuntamiento
         * @param {Ayuntamiento} [ayuntamiento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaInformacionAyuntamiento: async (ayuntamiento?: Ayuntamiento, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ayuntamiento/configuracion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ayuntamiento, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDocumento: async (documento?: Documento, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guardar-documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documento, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentos: async (apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartado' is not null or undefined
            assertParamExists('obtenerDocumentos', 'apartado', apartado)
            // verify required parameter 'modulo' is not null or undefined
            assertParamExists('obtenerDocumentos', 'modulo', modulo)
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumentos', 'ejercicio', ejercicio)
            // verify required parameter 'trimestre' is not null or undefined
            assertParamExists('obtenerDocumentos', 'trimestre', trimestre)
            // verify required parameter 'tipoDocumento' is not null or undefined
            assertParamExists('obtenerDocumentos', 'tipoDocumento', tipoDocumento)
            const localVarPath = `/obtener-documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apartado !== undefined) {
                localVarQueryParameter['apartado'] = apartado;
            }

            if (modulo !== undefined) {
                localVarQueryParameter['modulo'] = modulo;
            }

            if (ejercicio !== undefined) {
                localVarQueryParameter['ejercicio'] = ejercicio;
            }

            if (trimestre !== undefined) {
                localVarQueryParameter['trimestre'] = trimestre;
            }

            if (tipoDocumento !== undefined) {
                localVarQueryParameter['tipoDocumento'] = tipoDocumento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AyuntamientoApi - functional programming interface
 * @export
 */
export const AyuntamientoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AyuntamientoApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumento(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumento(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Guarda Información del ayuntamiento
         * @param {Ayuntamiento} [ayuntamiento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardaInformacionAyuntamiento(ayuntamiento?: Ayuntamiento, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardaInformacionAyuntamiento(ayuntamiento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarDocumento(documento?: Documento, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarDocumento(documento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AyuntamientoApi - factory interface
 * @export
 */
export const AyuntamientoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AyuntamientoApiFp(configuration)
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumento(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Guarda Información del ayuntamiento
         * @param {Ayuntamiento} [ayuntamiento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaInformacionAyuntamiento(ayuntamiento?: Ayuntamiento, options?: any): AxiosPromise<void> {
            return localVarFp.guardaInformacionAyuntamiento(ayuntamiento, options).then((request) => request(axios, basePath));
        },
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDocumento(documento?: Documento, options?: any): AxiosPromise<void> {
            return localVarFp.guardarDocumento(documento, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AyuntamientoApi - object-oriented interface
 * @export
 * @class AyuntamientoApi
 * @extends {BaseAPI}
 */
export class AyuntamientoApi extends BaseAPI {
    /**
     * Elimina un documento por su ID.
     * @param {number} id ID del documento a eliminar.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AyuntamientoApi
     */
    public eliminarDocumento(id: number, options?: any) {
        return AyuntamientoApiFp(this.configuration).eliminarDocumento(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Guarda Información del ayuntamiento
     * @param {Ayuntamiento} [ayuntamiento] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AyuntamientoApi
     */
    public guardaInformacionAyuntamiento(ayuntamiento?: Ayuntamiento, options?: any) {
        return AyuntamientoApiFp(this.configuration).guardaInformacionAyuntamiento(ayuntamiento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Guarda un documento.
     * @param {Documento} [documento] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AyuntamientoApi
     */
    public guardarDocumento(documento?: Documento, options?: any) {
        return AyuntamientoApiFp(this.configuration).guardarDocumento(documento, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene los documentos que pertenecen a una etapa.
     * @param {string} apartado apartado general.
     * @param {string} modulo Módulo de los documentos.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} trimestre Trimestre de los documentos.
     * @param {string} tipoDocumento El tipo de documento solicitado.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AyuntamientoApi
     */
    public obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any) {
        return AyuntamientoApiFp(this.configuration).obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentoApi - axios parameter creator
 * @export
 */
export const DocumentoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un documento en específico.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} clave Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumento: async (ejercicio: number, clave: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumento', 'ejercicio', ejercicio)
            // verify required parameter 'clave' is not null or undefined
            assertParamExists('obtenerDocumento', 'clave', clave)
            const localVarPath = `/documento/{ejercicio}/doc`
                .replace(`{${"ejercicio"}}`, encodeURIComponent(String(ejercicio)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clave !== undefined) {
                localVarQueryParameter['clave'] = clave;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene un documento en específico usando su clave.
         * @param {string} nombre Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtieneDocumentos: async (nombre: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'nombre' is not null or undefined
            assertParamExists('obtieneDocumentos', 'nombre', nombre)
            const localVarPath = `/documentos-soporte/{nombre}/doc`
                .replace(`{${"nombre"}}`, encodeURIComponent(String(nombre)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentoApi - functional programming interface
 * @export
 */
export const DocumentoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un documento en específico.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} clave Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumento(ejercicio: number, clave: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumento(ejercicio, clave, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtiene un documento en específico usando su clave.
         * @param {string} nombre Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtieneDocumentos(nombre: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtieneDocumentos(nombre, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentoApi - factory interface
 * @export
 */
export const DocumentoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentoApiFp(configuration)
    return {
        /**
         * Obtiene un documento en específico.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} clave Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumento(ejercicio: number, clave: number, options?: any): AxiosPromise<void> {
            return localVarFp.obtenerDocumento(ejercicio, clave, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene un documento en específico usando su clave.
         * @param {string} nombre Clave del documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtieneDocumentos(nombre: string, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtieneDocumentos(nombre, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentoApi - object-oriented interface
 * @export
 * @class DocumentoApi
 * @extends {BaseAPI}
 */
export class DocumentoApi extends BaseAPI {
    /**
     * Obtiene un documento en específico.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} clave Clave del documento.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtenerDocumento(ejercicio: number, clave: number, options?: any) {
        return DocumentoApiFp(this.configuration).obtenerDocumento(ejercicio, clave, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene un documento en específico usando su clave.
     * @param {string} nombre Clave del documento.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoApi
     */
    public obtieneDocumentos(nombre: string, options?: any) {
        return DocumentoApiFp(this.configuration).obtieneDocumentos(nombre, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentoBase64Api - axios parameter creator
 * @export
 */
export const DocumentoBase64ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un documento en específico a través de su id.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoBase64: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('obtenerDocumentoBase64', 'id', id)
            const localVarPath = `/documentoBase64/{id}/documento`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentoBase64Api - functional programming interface
 * @export
 */
export const DocumentoBase64ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentoBase64ApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un documento en específico a través de su id.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentoBase64(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentoBase64(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentoBase64Api - factory interface
 * @export
 */
export const DocumentoBase64ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentoBase64ApiFp(configuration)
    return {
        /**
         * Obtiene un documento en específico a través de su id.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentoBase64(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerDocumentoBase64(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentoBase64Api - object-oriented interface
 * @export
 * @class DocumentoBase64Api
 * @extends {BaseAPI}
 */
export class DocumentoBase64Api extends BaseAPI {
    /**
     * Obtiene un documento en específico a través de su id.
     * @param {number} id Identificador únido de documento.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoBase64Api
     */
    public obtenerDocumentoBase64(id: number, options?: any) {
        return DocumentoBase64ApiFp(this.configuration).obtenerDocumentoBase64(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentoChuckApi - axios parameter creator
 * @export
 */
export const DocumentoChuckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Guarda un documento usando la estrategia chuck
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaDocumentoChuck: async (inlineObject?: InlineObject, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/documentoChuck/guardarDocumentoChuck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentoChuckApi - functional programming interface
 * @export
 */
export const DocumentoChuckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentoChuckApiAxiosParamCreator(configuration)
    return {
        /**
         * Guarda un documento usando la estrategia chuck
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardaDocumentoChuck(inlineObject?: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardaDocumentoChuck(inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentoChuckApi - factory interface
 * @export
 */
export const DocumentoChuckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentoChuckApiFp(configuration)
    return {
        /**
         * Guarda un documento usando la estrategia chuck
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaDocumentoChuck(inlineObject?: InlineObject, options?: any): AxiosPromise<void> {
            return localVarFp.guardaDocumentoChuck(inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentoChuckApi - object-oriented interface
 * @export
 * @class DocumentoChuckApi
 * @extends {BaseAPI}
 */
export class DocumentoChuckApi extends BaseAPI {
    /**
     * Guarda un documento usando la estrategia chuck
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentoChuckApi
     */
    public guardaDocumentoChuck(inlineObject?: InlineObject, options?: any) {
        return DocumentoChuckApiFp(this.configuration).guardaDocumentoChuck(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentosInformacionPublicaApi - axios parameter creator
 * @export
 */
export const DocumentosInformacionPublicaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los documentos del apartado información pública
         * @param {string} apartado Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosInformacionPublica: async (apartado: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartado' is not null or undefined
            assertParamExists('obtenerDocumentosInformacionPublica', 'apartado', apartado)
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumentosInformacionPublica', 'ejercicio', ejercicio)
            // verify required parameter 'trimestre' is not null or undefined
            assertParamExists('obtenerDocumentosInformacionPublica', 'trimestre', trimestre)
            // verify required parameter 'articulo' is not null or undefined
            assertParamExists('obtenerDocumentosInformacionPublica', 'articulo', articulo)
            // verify required parameter 'fraccion' is not null or undefined
            assertParamExists('obtenerDocumentosInformacionPublica', 'fraccion', fraccion)
            const localVarPath = `/informacion-publica/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apartado !== undefined) {
                localVarQueryParameter['apartado'] = apartado;
            }

            if (ejercicio !== undefined) {
                localVarQueryParameter['ejercicio'] = ejercicio;
            }

            if (trimestre !== undefined) {
                localVarQueryParameter['trimestre'] = trimestre;
            }

            if (articulo !== undefined) {
                localVarQueryParameter['articulo'] = articulo;
            }

            if (fraccion !== undefined) {
                localVarQueryParameter['fraccion'] = fraccion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentosInformacionPublicaApi - functional programming interface
 * @export
 */
export const DocumentosInformacionPublicaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentosInformacionPublicaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los documentos del apartado información pública
         * @param {string} apartado Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentosInformacionPublica(apartado: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentosInformacionPublica(apartado, ejercicio, trimestre, articulo, fraccion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentosInformacionPublicaApi - factory interface
 * @export
 */
export const DocumentosInformacionPublicaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentosInformacionPublicaApiFp(configuration)
    return {
        /**
         * Obtiene los documentos del apartado información pública
         * @param {string} apartado Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosInformacionPublica(apartado: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtenerDocumentosInformacionPublica(apartado, ejercicio, trimestre, articulo, fraccion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentosInformacionPublicaApi - object-oriented interface
 * @export
 * @class DocumentosInformacionPublicaApi
 * @extends {BaseAPI}
 */
export class DocumentosInformacionPublicaApi extends BaseAPI {
    /**
     * Obtiene los documentos del apartado información pública
     * @param {string} apartado Módulo de los documentos.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} trimestre Trimestre de los documentos.
     * @param {number} articulo Artículo del documento
     * @param {number} fraccion Fracción del artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentosInformacionPublicaApi
     */
    public obtenerDocumentosInformacionPublica(apartado: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any) {
        return DocumentosInformacionPublicaApiFp(this.configuration).obtenerDocumentosInformacionPublica(apartado, ejercicio, trimestre, articulo, fraccion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentosInstanciaApi - axios parameter creator
 * @export
 */
export const DocumentosInstanciaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los documentos del apartado instancia
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosInstancia: async (modulo: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'modulo' is not null or undefined
            assertParamExists('obtenerDocumentosInstancia', 'modulo', modulo)
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumentosInstancia', 'ejercicio', ejercicio)
            // verify required parameter 'trimestre' is not null or undefined
            assertParamExists('obtenerDocumentosInstancia', 'trimestre', trimestre)
            // verify required parameter 'articulo' is not null or undefined
            assertParamExists('obtenerDocumentosInstancia', 'articulo', articulo)
            // verify required parameter 'fraccion' is not null or undefined
            assertParamExists('obtenerDocumentosInstancia', 'fraccion', fraccion)
            const localVarPath = `/instancia/documentos`
                .replace(`{${"trimestre"}}`, encodeURIComponent(String(trimestre)))
                .replace(`{${"articulo"}}`, encodeURIComponent(String(articulo)))
                .replace(`{${"fraccion"}}`, encodeURIComponent(String(fraccion)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (modulo !== undefined) {
                localVarQueryParameter['modulo'] = modulo;
            }

            if (ejercicio !== undefined) {
                localVarQueryParameter['ejercicio'] = ejercicio;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentosInstanciaApi - functional programming interface
 * @export
 */
export const DocumentosInstanciaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentosInstanciaApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los documentos del apartado instancia
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentosInstancia(modulo: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentosInstancia(modulo, ejercicio, trimestre, articulo, fraccion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentosInstanciaApi - factory interface
 * @export
 */
export const DocumentosInstanciaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentosInstanciaApiFp(configuration)
    return {
        /**
         * Obtiene los documentos del apartado instancia
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {number} articulo Artículo del documento
         * @param {number} fraccion Fracción del artículo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosInstancia(modulo: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtenerDocumentosInstancia(modulo, ejercicio, trimestre, articulo, fraccion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentosInstanciaApi - object-oriented interface
 * @export
 * @class DocumentosInstanciaApi
 * @extends {BaseAPI}
 */
export class DocumentosInstanciaApi extends BaseAPI {
    /**
     * Obtiene los documentos del apartado instancia
     * @param {string} modulo Módulo de los documentos.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} trimestre Trimestre de los documentos.
     * @param {number} articulo Artículo del documento
     * @param {number} fraccion Fracción del artículo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentosInstanciaApi
     */
    public obtenerDocumentosInstancia(modulo: string, ejercicio: number, trimestre: number, articulo: number, fraccion: number, options?: any) {
        return DocumentosInstanciaApiFp(this.configuration).obtenerDocumentosInstancia(modulo, ejercicio, trimestre, articulo, fraccion, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentosSevacApi - axios parameter creator
 * @export
 */
export const DocumentosSevacApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los documentos del apartado sevac
         * @param {string} apartado Apartado general de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosSevac: async (apartado: string, ejercicio: number, trimestre: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartado' is not null or undefined
            assertParamExists('obtenerDocumentosSevac', 'apartado', apartado)
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumentosSevac', 'ejercicio', ejercicio)
            // verify required parameter 'trimestre' is not null or undefined
            assertParamExists('obtenerDocumentosSevac', 'trimestre', trimestre)
            const localVarPath = `/sevac/documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apartado !== undefined) {
                localVarQueryParameter['apartado'] = apartado;
            }

            if (ejercicio !== undefined) {
                localVarQueryParameter['ejercicio'] = ejercicio;
            }

            if (trimestre !== undefined) {
                localVarQueryParameter['trimestre'] = trimestre;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentosSevacApi - functional programming interface
 * @export
 */
export const DocumentosSevacApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentosSevacApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los documentos del apartado sevac
         * @param {string} apartado Apartado general de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentosSevac(apartado: string, ejercicio: number, trimestre: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentosSevac(apartado, ejercicio, trimestre, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentosSevacApi - factory interface
 * @export
 */
export const DocumentosSevacApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentosSevacApiFp(configuration)
    return {
        /**
         * Obtiene los documentos del apartado sevac
         * @param {string} apartado Apartado general de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentosSevac(apartado: string, ejercicio: number, trimestre: number, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtenerDocumentosSevac(apartado, ejercicio, trimestre, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentosSevacApi - object-oriented interface
 * @export
 * @class DocumentosSevacApi
 * @extends {BaseAPI}
 */
export class DocumentosSevacApi extends BaseAPI {
    /**
     * Obtiene los documentos del apartado sevac
     * @param {string} apartado Apartado general de los documentos.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} trimestre Trimestre de los documentos.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentosSevacApi
     */
    public obtenerDocumentosSevac(apartado: string, ejercicio: number, trimestre: number, options?: any) {
        return DocumentosSevacApiFp(this.configuration).obtenerDocumentosSevac(apartado, ejercicio, trimestre, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EliminarDocumentoApi - axios parameter creator
 * @export
 */
export const EliminarDocumentoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('eliminarDocumento', 'id', id)
            const localVarPath = `/eliminar-documento/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EliminarDocumentoApi - functional programming interface
 * @export
 */
export const EliminarDocumentoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EliminarDocumentoApiAxiosParamCreator(configuration)
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eliminarDocumento(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eliminarDocumento(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EliminarDocumentoApi - factory interface
 * @export
 */
export const EliminarDocumentoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EliminarDocumentoApiFp(configuration)
    return {
        /**
         * Elimina un documento por su ID.
         * @param {number} id ID del documento a eliminar.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eliminarDocumento(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.eliminarDocumento(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EliminarDocumentoApi - object-oriented interface
 * @export
 * @class EliminarDocumentoApi
 * @extends {BaseAPI}
 */
export class EliminarDocumentoApi extends BaseAPI {
    /**
     * Elimina un documento por su ID.
     * @param {number} id ID del documento a eliminar.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EliminarDocumentoApi
     */
    public eliminarDocumento(id: number, options?: any) {
        return EliminarDocumentoApiFp(this.configuration).eliminarDocumento(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuardarDocumentoApi - axios parameter creator
 * @export
 */
export const GuardarDocumentoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDocumento: async (documento?: Documento, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/guardar-documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documento, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuardarDocumentoApi - functional programming interface
 * @export
 */
export const GuardarDocumentoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuardarDocumentoApiAxiosParamCreator(configuration)
    return {
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardarDocumento(documento?: Documento, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardarDocumento(documento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuardarDocumentoApi - factory interface
 * @export
 */
export const GuardarDocumentoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuardarDocumentoApiFp(configuration)
    return {
        /**
         * Guarda un documento.
         * @param {Documento} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardarDocumento(documento?: Documento, options?: any): AxiosPromise<void> {
            return localVarFp.guardarDocumento(documento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuardarDocumentoApi - object-oriented interface
 * @export
 * @class GuardarDocumentoApi
 * @extends {BaseAPI}
 */
export class GuardarDocumentoApi extends BaseAPI {
    /**
     * Guarda un documento.
     * @param {Documento} [documento] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuardarDocumentoApi
     */
    public guardarDocumento(documento?: Documento, options?: any) {
        return GuardarDocumentoApiFp(this.configuration).guardarDocumento(documento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeyGeneralApi - axios parameter creator
 * @export
 */
export const LeyGeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Guarda un documento de soporte de ley general.
         * @param {Array<Documento>} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaDocumentos: async (documento?: Array<Documento>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/ley-general/documento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documento, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeyGeneralApi - functional programming interface
 * @export
 */
export const LeyGeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeyGeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * Guarda un documento de soporte de ley general.
         * @param {Array<Documento>} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async guardaDocumentos(documento?: Array<Documento>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.guardaDocumentos(documento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeyGeneralApi - factory interface
 * @export
 */
export const LeyGeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeyGeneralApiFp(configuration)
    return {
        /**
         * Guarda un documento de soporte de ley general.
         * @param {Array<Documento>} [documento] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        guardaDocumentos(documento?: Array<Documento>, options?: any): AxiosPromise<void> {
            return localVarFp.guardaDocumentos(documento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeyGeneralApi - object-oriented interface
 * @export
 * @class LeyGeneralApi
 * @extends {BaseAPI}
 */
export class LeyGeneralApi extends BaseAPI {
    /**
     * Guarda un documento de soporte de ley general.
     * @param {Array<Documento>} [documento] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeyGeneralApi
     */
    public guardaDocumentos(documento?: Array<Documento>, options?: any) {
        return LeyGeneralApiFp(this.configuration).guardaDocumentos(documento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObtenerDocumentosApi - axios parameter creator
 * @export
 */
export const ObtenerDocumentosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentos: async (apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartado' is not null or undefined
            assertParamExists('obtenerDocumentos', 'apartado', apartado)
            // verify required parameter 'modulo' is not null or undefined
            assertParamExists('obtenerDocumentos', 'modulo', modulo)
            // verify required parameter 'ejercicio' is not null or undefined
            assertParamExists('obtenerDocumentos', 'ejercicio', ejercicio)
            // verify required parameter 'trimestre' is not null or undefined
            assertParamExists('obtenerDocumentos', 'trimestre', trimestre)
            // verify required parameter 'tipoDocumento' is not null or undefined
            assertParamExists('obtenerDocumentos', 'tipoDocumento', tipoDocumento)
            const localVarPath = `/obtener-documentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (apartado !== undefined) {
                localVarQueryParameter['apartado'] = apartado;
            }

            if (modulo !== undefined) {
                localVarQueryParameter['modulo'] = modulo;
            }

            if (ejercicio !== undefined) {
                localVarQueryParameter['ejercicio'] = ejercicio;
            }

            if (trimestre !== undefined) {
                localVarQueryParameter['trimestre'] = trimestre;
            }

            if (tipoDocumento !== undefined) {
                localVarQueryParameter['tipoDocumento'] = tipoDocumento;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObtenerDocumentosApi - functional programming interface
 * @export
 */
export const ObtenerDocumentosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObtenerDocumentosApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Documento>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObtenerDocumentosApi - factory interface
 * @export
 */
export const ObtenerDocumentosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObtenerDocumentosApiFp(configuration)
    return {
        /**
         * Obtiene los documentos que pertenecen a una etapa.
         * @param {string} apartado apartado general.
         * @param {string} modulo Módulo de los documentos.
         * @param {number} ejercicio Ejercicio fiscal.
         * @param {number} trimestre Trimestre de los documentos.
         * @param {string} tipoDocumento El tipo de documento solicitado.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any): AxiosPromise<Array<Documento>> {
            return localVarFp.obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObtenerDocumentosApi - object-oriented interface
 * @export
 * @class ObtenerDocumentosApi
 * @extends {BaseAPI}
 */
export class ObtenerDocumentosApi extends BaseAPI {
    /**
     * Obtiene los documentos que pertenecen a una etapa.
     * @param {string} apartado apartado general.
     * @param {string} modulo Módulo de los documentos.
     * @param {number} ejercicio Ejercicio fiscal.
     * @param {number} trimestre Trimestre de los documentos.
     * @param {string} tipoDocumento El tipo de documento solicitado.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObtenerDocumentosApi
     */
    public obtenerDocumentos(apartado: string, modulo: string, ejercicio: number, trimestre: number, tipoDocumento: string, options?: any) {
        return ObtenerDocumentosApiFp(this.configuration).obtenerDocumentos(apartado, modulo, ejercicio, trimestre, tipoDocumento, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObtenerInformacionAyuntamientoApi - axios parameter creator
 * @export
 */
export const ObtenerInformacionAyuntamientoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene un información del ayuntamiento.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerInformacionAyuntamiento: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('obtenerInformacionAyuntamiento', 'id', id)
            const localVarPath = `/informacion/{id}/ayuntamiento`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObtenerInformacionAyuntamientoApi - functional programming interface
 * @export
 */
export const ObtenerInformacionAyuntamientoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObtenerInformacionAyuntamientoApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene un información del ayuntamiento.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async obtenerInformacionAyuntamiento(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.obtenerInformacionAyuntamiento(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObtenerInformacionAyuntamientoApi - factory interface
 * @export
 */
export const ObtenerInformacionAyuntamientoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObtenerInformacionAyuntamientoApiFp(configuration)
    return {
        /**
         * Obtiene un información del ayuntamiento.
         * @param {number} id Identificador únido de documento.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        obtenerInformacionAyuntamiento(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.obtenerInformacionAyuntamiento(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObtenerInformacionAyuntamientoApi - object-oriented interface
 * @export
 * @class ObtenerInformacionAyuntamientoApi
 * @extends {BaseAPI}
 */
export class ObtenerInformacionAyuntamientoApi extends BaseAPI {
    /**
     * Obtiene un información del ayuntamiento.
     * @param {number} id Identificador únido de documento.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObtenerInformacionAyuntamientoApi
     */
    public obtenerInformacionAyuntamiento(id: number, options?: any) {
        return ObtenerInformacionAyuntamientoApiFp(this.configuration).obtenerInformacionAyuntamiento(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PetApi - axios parameter creator
 * @export
 */
export const PetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary uploads an image
         * @param {number} petId ID of pet to update
         * @param {string} [additionalMetadata] Additional Metadata
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (petId: number, additionalMetadata?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'petId' is not null or undefined
            assertParamExists('uploadFile', 'petId', petId)
            const localVarPath = `/pet/{petId}/uploadImage`
                .replace(`{${"petId"}}`, encodeURIComponent(String(petId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (additionalMetadata !== undefined) {
                localVarQueryParameter['additionalMetadata'] = additionalMetadata;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PetApi - functional programming interface
 * @export
 */
export const PetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PetApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary uploads an image
         * @param {number} petId ID of pet to update
         * @param {string} [additionalMetadata] Additional Metadata
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(petId: number, additionalMetadata?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(petId, additionalMetadata, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PetApi - factory interface
 * @export
 */
export const PetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PetApiFp(configuration)
    return {
        /**
         * 
         * @summary uploads an image
         * @param {number} petId ID of pet to update
         * @param {string} [additionalMetadata] Additional Metadata
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(petId: number, additionalMetadata?: string, body?: string, options?: any): AxiosPromise<ApiResponse> {
            return localVarFp.uploadFile(petId, additionalMetadata, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PetApi - object-oriented interface
 * @export
 * @class PetApi
 * @extends {BaseAPI}
 */
export class PetApi extends BaseAPI {
    /**
     * 
     * @summary uploads an image
     * @param {number} petId ID of pet to update
     * @param {string} [additionalMetadata] Additional Metadata
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PetApi
     */
    public uploadFile(petId: number, additionalMetadata?: string, body?: string, options?: any) {
        return PetApiFp(this.configuration).uploadFile(petId, additionalMetadata, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns a map of status codes to quantities
         * @summary Returns pet inventories by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventory: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/store/inventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns a map of status codes to quantities
         * @summary Returns pet inventories by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInventory(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInventory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * Returns a map of status codes to quantities
         * @summary Returns pet inventories by status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventory(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getInventory(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * Returns a map of status codes to quantities
     * @summary Returns pet inventories by status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public getInventory(options?: any) {
        return StoreApiFp(this.configuration).getInventory(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {User} [user] Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (user?: User, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates list of users with given input array
         * @summary Creates list of users with given input array
         * @param {Array<User>} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersWithListInput: async (user?: Array<User>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/createWithList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (username: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('deleteUser', 'username', username)
            const localVarPath = `/user/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by user name
         * @param {string} username The name that needs to be fetched. Use user1 for testing. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByName: async (username: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUserByName', 'username', username)
            const localVarPath = `/user/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs user into the system
         * @param {string} [username] The user name for login
         * @param {string} [password] The password for login in clear text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser: async (username?: string, password?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {string} username name that need to be deleted
         * @param {User} [user] Update an existent user in the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (username: string, user?: User, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('updateUser', 'username', username)
            const localVarPath = `/user/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            // authentication jwt required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {User} [user] Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates list of users with given input array
         * @summary Creates list of users with given input array
         * @param {Array<User>} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUsersWithListInput(user?: Array<User>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUsersWithListInput(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(username: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by user name
         * @param {string} username The name that needs to be fetched. Use user1 for testing. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserByName(username: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserByName(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logs user into the system
         * @param {string} [username] The user name for login
         * @param {string} [password] The password for login in clear text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginUser(username?: string, password?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginUser(username, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logoutUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logoutUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {string} username name that need to be deleted
         * @param {User} [user] Update an existent user in the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(username: string, user?: User, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(username, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * This can only be done by the logged in user.
         * @summary Create user
         * @param {User} [user] Created user object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(user?: User, options?: any): AxiosPromise<User> {
            return localVarFp.createUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates list of users with given input array
         * @summary Creates list of users with given input array
         * @param {Array<User>} [user] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUsersWithListInput(user?: Array<User>, options?: any): AxiosPromise<User> {
            return localVarFp.createUsersWithListInput(user, options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Delete user
         * @param {string} username The name that needs to be deleted
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by user name
         * @param {string} username The name that needs to be fetched. Use user1 for testing. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserByName(username: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserByName(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs user into the system
         * @param {string} [username] The user name for login
         * @param {string} [password] The password for login in clear text
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUser(username?: string, password?: string, options?: any): AxiosPromise<string> {
            return localVarFp.loginUser(username, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logs out current logged in user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logoutUser(options?: any): AxiosPromise<void> {
            return localVarFp.logoutUser(options).then((request) => request(axios, basePath));
        },
        /**
         * This can only be done by the logged in user.
         * @summary Update user
         * @param {string} username name that need to be deleted
         * @param {User} [user] Update an existent user in the store
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(username: string, user?: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(username, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * This can only be done by the logged in user.
     * @summary Create user
     * @param {User} [user] Created user object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(user?: User, options?: any) {
        return UserApiFp(this.configuration).createUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates list of users with given input array
     * @summary Creates list of users with given input array
     * @param {Array<User>} [user] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUsersWithListInput(user?: Array<User>, options?: any) {
        return UserApiFp(this.configuration).createUsersWithListInput(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Delete user
     * @param {string} username The name that needs to be deleted
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(username: string, options?: any) {
        return UserApiFp(this.configuration).deleteUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by user name
     * @param {string} username The name that needs to be fetched. Use user1 for testing. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserByName(username: string, options?: any) {
        return UserApiFp(this.configuration).getUserByName(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs user into the system
     * @param {string} [username] The user name for login
     * @param {string} [password] The password for login in clear text
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public loginUser(username?: string, password?: string, options?: any) {
        return UserApiFp(this.configuration).loginUser(username, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logs out current logged in user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public logoutUser(options?: any) {
        return UserApiFp(this.configuration).logoutUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This can only be done by the logged in user.
     * @summary Update user
     * @param {string} username name that need to be deleted
     * @param {User} [user] Update an existent user in the store
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUser(username: string, user?: User, options?: any) {
        return UserApiFp(this.configuration).updateUser(username, user, options).then((request) => request(this.axios, this.basePath));
    }
}


