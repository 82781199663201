


















import { Component, Provide, VModel, Vue } from 'vue-property-decorator';
import Ejercicios from '@/ejercicios/ejercicios.vue';

@Component({
  components: {
    ejercicios: Ejercicios,
  },
})
export default class SEVAC extends Vue {
  ejercicioGeneral = 2023;
}
