

















import Vue from 'vue';
import Component from 'vue-class-component';

import Ejercicios from '@/ejercicios/ejercicios.vue';
import InformaciónPublica from '@/informacion-publica/informacion-publica.vue';

import { mapGetters } from 'vuex';

@Component({
  components: {
    ejercicios: Ejercicios,
    'informacion-publica': InformaciónPublica,
  },
  computed: {
    ...mapGetters([
      'esInformacionPublica',
      'esInstanciaTecnicaEvaluacion',
      'articulo',
      'fraccion',
      'esSevac',
      'esLeyGeneral',
      'esLeyDisciplina',
    ]),
  },
})
export default class BaseComponent extends Vue {
  readonly esInformacionPublica!: boolean;
  readonly esLeyGeneral!: boolean;
  readonly esLeyDisciplina!: boolean;
  readonly esInstanciaTecnicaEvaluacion!: boolean;
  readonly esSevac!: boolean;
  readonly articulo!: number;
  readonly fraccion!: number;
}
