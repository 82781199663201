import { Documento } from '@/shared/openapi-client';
import { Module } from 'vuex';

export const accountStore: Module<any, any> = {
  state: {
    logon: false,
    userIdentity: null,
    authenticated: false,
    ribbonOnProfiles: '',
    activeProfiles: '',
    articulo: null,
    fraccion: null,
    esInformacionPublica: false,
    documentos: [],
    esInstanciaTecnicaEvaluacion: false,
    modulo: null,
    trimestre: null,
    ejercicio: null,
    nombreDocumento: null,
    idDocumento: null,
    esLeyGeneral: null,
    seleccionarDocumento: null,
    apartado: null,
    esSevac: null,
  },
  getters: {
    logon: state => state.logon,
    account: state => state.userIdentity,
    authenticated: state => state.authenticated,
    activeProfiles: state => state.activeProfiles,
    ribbonOnProfiles: state => state.ribbonOnProfiles,
  },
  mutations: {
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setRibbonOnProfiles(state, ribbon) {
      state.ribbonOnProfiles = ribbon;
    },
  },
  actions: {
    accountStore(ctx) {},
  },
};
